import { Popover } from '@sydney-broker-ui/ios';
import React, { useEffect, useState } from 'react';

import CheckBoxListFilter from '../../../../components/common/check-box-list-filter/CheckBoxListFilter';
import MoreFilters from '../../../public/medical-plans/MoreFilters';

import { getResetFilters } from '../../../../components/common/check-box-list-filter/checkBoxListFilterUtils';
import { getResetSwitchFilters } from '../../../../components/common/switch-list-filter/switchFIlterUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { getActiveSortValue, getInitialBenefitTierFilters, getInitialSwitchFilters, getPlansForTab, getSortOptions, getUpdatedSortOptions } from '../../../public/medical-plans/medicalPlansUtils';
import { getInitialProductTypeFilters } from './productSelectionFiltersUtils';

import { PRODUCT_FILTER_TYPES } from '../constants';
import './ProductSelectionFilters.scss';

const ProductSelectionFilters: React.FC<ProductSelectionFiltersProps> = ({ labels, medicalProducts, onFiltersChange, onSortChange }) => {
    const [activeFilter, setActiveFilter] = useState('');
    const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);

    const {
        productTypeFilters,
        productsSortOptions,
        productsSwitchFilters,
        productsBenefitsTiers,
        selectedProductTab,
        savedProviders,
        matchProvidersToPlans,
        matchMedicationsToPlans,
        drugsList,
        coveredMedicationsList,
        updateProductTypeFilters,
        updateProductsSortOptions,
        updateProductSwitchFilters,
        updateProductsBenefitsTiers,
    } = useGlobalStore((state) => state);

    const initialiseFilters = () => {
        const plansForTab = getPlansForTab(selectedProductTab, medicalProducts);
        const initialSortOptions = getSortOptions(productsSortOptions, labels);
        const initialProductTypeFilters = getInitialProductTypeFilters(productTypeFilters, plansForTab, labels);
        const initialSwitchFilters = getInitialSwitchFilters(productsSwitchFilters, plansForTab, labels, savedProviders, matchProvidersToPlans, matchMedicationsToPlans, drugsList, coveredMedicationsList);
        const initialBenefitsTierFilters = getInitialBenefitTierFilters(productsBenefitsTiers, plansForTab, labels)
        updateProductTypeFilters(initialProductTypeFilters);
        updateProductsSortOptions(initialSortOptions);
        updateProductSwitchFilters(initialSwitchFilters);
        updateProductsBenefitsTiers(initialBenefitsTierFilters)
    }

    useEffect(() => {
        initialiseFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProductTab, medicalProducts]);

    useEffect(() => {
        if (matchProvidersToPlans || matchMedicationsToPlans) {
            const plansForTab = getPlansForTab(selectedProductTab, medicalProducts);
            const initialSwitchFilters = getInitialSwitchFilters(productsSwitchFilters, plansForTab, labels, savedProviders, matchProvidersToPlans, matchMedicationsToPlans, drugsList, coveredMedicationsList, false);

            updateProductSwitchFilters(initialSwitchFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchProvidersToPlans, matchMedicationsToPlans])

    const onSortValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedSortValue = getUpdatedSortOptions(e.target.value, productsSortOptions);
        updateProductsSortOptions(updatedSortValue);
        onSortChange(getActiveSortValue(updatedSortValue));
    };

    const handleFilterClick = (type: string, currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
        setAnchor(currentTarget);
        setActiveFilter(type);
    };

    const resetAllFilters = () => {
        const filters: ProductFilters = {
            selectedProductTab,
            productTypeFilters: getResetFilters(productTypeFilters),
            productsBenefitsTiers: getResetFilters(productsBenefitsTiers),
            productsSwitchFilters: getResetSwitchFilters(productsSwitchFilters)
        };

        updateProductTypeFilters(filters.productTypeFilters);
        updateProductSwitchFilters(filters.productsSwitchFilters);
        updateProductsBenefitsTiers(filters.productsBenefitsTiers);

        onFiltersChange(filters);
    };

    const getFilterBody = () => {
        switch (activeFilter) {
            case PRODUCT_FILTER_TYPES.PRODUCT_TYPES:
                return (
                    <CheckBoxListFilter
                        filterOptions={productTypeFilters}
                        showFooterButtons={false}
                        onApplyClick={(appliedFilters: CheckBoxFilterOption[]) => {
                            const filters: ProductFilters = { selectedProductTab, productTypeFilters: appliedFilters, productsSwitchFilters, productsBenefitsTiers };

                            updateProductTypeFilters(appliedFilters);
                            onFiltersChange(filters);
                        }}
                    />
                );
            case PRODUCT_FILTER_TYPES.MORE:
                return (
                    <MoreFilters
                        labels={{
                            BENEFIT_TIER: labels.BENEFIT_TIER,
                        }}
                        switchFilters={productsSwitchFilters}
                        checkBoxFilters={productsBenefitsTiers}
                        onSwitchFilterApply={(appliedFilters: SwitchFilter[]) => {
                            const filters: ProductFilters = { selectedProductTab, productTypeFilters, productsSwitchFilters: appliedFilters, productsBenefitsTiers };

                            updateProductSwitchFilters(appliedFilters);
                            onFiltersChange(filters);
                        }}
                        onCheckBoxFilterApply={(appliedFilters: CheckBoxFilterOption[]) => {
                            const filters: ProductFilters = { selectedProductTab, productTypeFilters, productsSwitchFilters, productsBenefitsTiers: appliedFilters };

                            updateProductsBenefitsTiers(appliedFilters);
                            onFiltersChange(filters);
                        }}
                    />
                );
            default:
                return <div />;
        }
    };

    const isFilterApplied = (filterType: string) => {
        if (filterType === PRODUCT_FILTER_TYPES.PRODUCT_TYPES && productTypeFilters.some((filter) => filter.isChecked)) {
            return 'active';
        } else if (filterType === PRODUCT_FILTER_TYPES.MORE && (productsBenefitsTiers.some((filter) => filter.isChecked) || productsSwitchFilters.some((filter) => filter.enabled))) {
            return 'active';
        } else {
            return '';
        }
    }

    return (
        <div className="filters-wrapper">
            <div className="filters">
                {activeFilter && anchor && (
                    <div className="filter-popover-wrapper">
                        <Popover id="plan-filter-popover" placement={'bottom'} anchorEl={anchor} onClickOutside={() => setActiveFilter('')}>
                            <div className="filter-body-container">{getFilterBody()}</div>
                        </Popover>
                    </div>
                )}
                <div className="monthly-cost">
                    <button
                        className={"filter-button " + isFilterApplied(PRODUCT_FILTER_TYPES.PRODUCT_TYPES)}
                        onClick={(e) => {
                            handleFilterClick(PRODUCT_FILTER_TYPES.PRODUCT_TYPES, e.currentTarget);
                        }}
                    >
                        {labels.PRODUCT_TYPES}
                    </button>
                </div>
                <div className="more-filters">
                    <button
                        className={"filter-button " + isFilterApplied(PRODUCT_FILTER_TYPES.MORE)}
                        onClick={(e) => {
                            handleFilterClick(PRODUCT_FILTER_TYPES.MORE, e.currentTarget);
                        }}
                    >
                        {labels.MORE_FILTERS}
                    </button>
                </div>
                <div className="more-filters">
                    <a
                        data-analytics="resetFiltersMedicalPlansIos"
                        href={'#'}
                        onClick={(e) => { e.preventDefault(); resetAllFilters(); }}
                        className="reset-filters"
                    >
                        {labels.RESET_FILTERS}
                    </a>
                </div>
            </div>
            <div>
                <div className="sort-by-wrapper">
                    <span className="sort-by-label"> {labels.SORT_BY}</span>
                    <div className="fwc-select-list sort-drop-down">
                        <select name="sort-by" aria-label="SORT BY" data-testid='sort-by' id="sort-by" onChange={(e) => onSortValueChange(e)} onBlur={() => { }} value={getActiveSortValue(productsSortOptions)?.value}>
                            {productsSortOptions?.map((option) => {
                                return (
                                    <option data-analytics={option.analytics} key={option.key} value={option.value}>
                                        {option.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductSelectionFilters