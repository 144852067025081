import { AxiosError, isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WELLPOINT_OFF_EXCHANGE_BASE_URLS } from '../../../api/api-name-list';
import { HTTP_STATUS_CODES, STATUSES } from '../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../api/services/tokenService';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, AGENT_TYPES, APPLICANT_TYPES, ATHENTICATE_AGENT_RESPONSE_CODE, NAV_PATHS, USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { resetUserData } from '../../secure/logout/LogoutPupupUtils';
import { getAgentSSOInfo, initiateAgentAuthentication } from './AgentSSOLoginService';

const { REACT_APP_ENV } = process.env;

const AgentSSOLogin: React.FC = () => {
    const location = useLocation();
    const { updateDeepLinkAgentSSOInfo, updateAgentSSOInfo } = useGlobalStore();
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });
    const navigate = useNavigate();

    const getAgentAuthInfo = async (agentSSOInfoResponse: AgentSSOInfo) => {
        const payload: AgentAuthenticationPayload = {
            userRole: agentSSOInfoResponse.agentType,
            firstName: agentSSOInfoResponse.agentInfo.agent[0].agentFName,
            lastName: agentSSOInfoResponse.agentInfo.agent[0].agentLName,
            email: "",
            agencyName: ""
        }
        return initiateAgentAuthentication(payload, agentSSOInfoResponse?.eventHeader?.brokerUserID).then((response) => {
            const agentAuthData: AgentAuthenticationResponse = response.data;
            if (response.status === HTTP_STATUS_CODES.SUCCESS && agentAuthData.responseMessage.responseCode === ATHENTICATE_AGENT_RESPONSE_CODE.SUCCESS) {
                return agentAuthData;
            } else {
                handleLoginServerError(null);
                return null;
            }
        }).catch(error => {
            console.error('error', error);
            setLoading(false);
            return null;
        })
    }

    const initiate = async () => {
        setLoading(true);
        const isSuccess = await getToken();
        if (isSuccess || REACT_APP_ENV === 'local') {
            getAgentSSOInfo(useGlobalStore.getState().deepLinkInfo.agentSSOInfo.ssoID).then(async (response) => {
                const agentSSOInfoResponse = response.data;
                setLoading(false);
                if (response.status === HTTP_STATUS_CODES.SUCCESS && agentSSOInfoResponse.status === STATUSES.SUCCESS) {
                    try {
                        if (agentSSOInfoResponse?.agentType === AGENT_ROLES.DELEGATE && agentSSOInfoResponse?.agentInfo?.agent?.length > 1) {
                            agentSSOInfoResponse.agentInfo.agent.splice(0, 1);
                        }
                        const agentAuthresponse = await getAgentAuthInfo(agentSSOInfoResponse);
                        if (agentAuthresponse) {
                            let agentSSOInfo: AgentSSOInfo = agentSSOInfoResponse;
                            agentSSOInfo.isAtk = true;
                            agentSSOInfo.agentInfo.agent[0].webAcctId = agentAuthresponse.webAcctId;
                            agentSSOInfo.agentInfo.agent[0].webGUID = agentAuthresponse.webGUID;
                            agentSSOInfo.agentInfo.agent[0].userId = agentAuthresponse.userId;
                            agentSSOInfo.agentInfo.agent[0].userRole = agentAuthresponse.userRole;
                            agentSSOInfo?.applicantInfo?.applicants?.applicant?.forEach(applicant => {
                                if (applicant?.relationship === APPLICANT_TYPES.PRIMARY || applicant?.relationship === APPLICANT_TYPES.SELF) {
                                    applicant.email = agentSSOInfo?.applicantInfo?.primaryEmailAddress;
                                }
                            });
                            updateAgentSSOInfo(agentSSOInfo);
                            if (useGlobalStore.getState().deepLinkInfo.agentSSOInfo.action === 'edit') {
                                if (agentSSOInfo.agentInfo.agent[0].agentType === AGENT_TYPES.HPA) {
                                    useGlobalStore.getState().updateIsHPAUser(true);
                                    useGlobalStore.getState().updateIsHCIDFlow(true);
                                    useUserStore.getState().updateRole(USER_ROLES.MEMBER);
                                } else if (agentSSOInfo.agentInfo.agent[0].agentType !== AGENT_TYPES.HPA && agentSSOInfo.memberInfo !== null) {
                                    useGlobalStore.getState().updateIsHPAUser(false);
                                    useGlobalStore.getState().updateIsHCIDFlow(true);
                                    useUserStore.getState().updateRole(USER_ROLES.MEMBER);
                                    useGlobalStore.getState().updateIsRenewalFlow(true);
                                }
                                navigate(NAV_PATHS.AGENTS_QUOTE_OVERVIEW);
                            } else if (agentSSOInfo.agentInfo.agent[0].agentType === AGENT_TYPES.HPA) {
                                useGlobalStore.getState().updateIsHPAUser(true);
                                useGlobalStore.getState().updateIsHCIDFlow(true);
                                useUserStore.getState().updateRole(USER_ROLES.MEMBER);
                                navigate(NAV_PATHS.AGENTS_BASICS_HCID);
                            } else if (agentSSOInfo.agentInfo.agent[0].agentType !== AGENT_TYPES.HPA && agentSSOInfo.memberInfo !== null) {
                                useGlobalStore.getState().updateIsHPAUser(false);
                                useGlobalStore.getState().updateIsHCIDFlow(true);
                                useUserStore.getState().updateRole(USER_ROLES.MEMBER);
                                useGlobalStore.getState().updateIsRenewalFlow(true);
                                navigate(NAV_PATHS.AGENTS_BASICS_HCID);
                            } else {
                                navigate(NAV_PATHS.AGENTS_BASICS);
                                useGlobalStore.getState().updateIsHCIDFlow(false);
                            }
                        }
                    } catch (error) {
                        console.error('error', error);
                        handleLoginServerError(null);
                        setLoading(false);
                    }
                } else {
                    handleLoginServerError(null);
                }
            }).catch(error => {
                console.error('error', error);
                setLoading(false);
            });
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `Agent SSO <b>Something went wrong. Please try again later.</b> `
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        resetUserData();
        const urlParams = new URLSearchParams(location.search);
        const isWellpointflag = window.location.href.includes('wellpoint.com/') || window.location.href.includes('/content/ios/wellpoint/');
        if (urlParams.has('action') && urlParams.get('action') === 'editacn' && urlParams.has('acn') && urlParams.get('acn') && isWellpointflag) {
            window.open(`${WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/secure/apply?acn=${urlParams.get('acn')}&atkEncryptedUser=${urlParams.get('atkEncryptedUser')}&source=acaRefreshPTBEdit`, '_self');
            return;
        }
        if (urlParams.has('ssoID') && urlParams.has('channel') && urlParams.has('action')) {
            updateDeepLinkAgentSSOInfo(urlParams.get('ssoID') ?? '', urlParams.get('channel') ?? '', urlParams.get('action') ?? '');
            initiate();
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.BAD_REQUEST.toString(),
                message: 'Agent SSO <b>Invalid URL parameters.</b>'
            });
        }
    }, []);


    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse && tokenResponse.data.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
                    }
                } catch (error: any) {
                    returnVal = false;
                    setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
                }
            }
        }
        catch (error: any) {
            returnVal = false;
            setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
        finally {
            return returnVal;
        }
    }

    const setTokenError = (responseCode, responseMessage) => {
        setLoginError({
            hasError: true,
            responseCode: responseCode ? responseCode : HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: responseMessage ? responseMessage : `Agent SSO <b>Something went wrong. Please try again later.</b> `
        });

    }

    const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = {
                hasError: true,
                responseCode: errorKey,
                message: 'Agent SSO Something went wrong. Please try again later.'
            };
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `Agent SSO <b>Something went wrong. Please try again later.</b> `
            });
        }

        setLoading(false);
    };

    return (<div>
        {(loading && !loginError.hasError) && <FullPageLoader />}
        {(!loading && loginError.hasError) && <div dangerouslySetInnerHTML={{ __html: loginError.message }}></div>}
    </div>)
}

export default AgentSSOLogin;