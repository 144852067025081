import { Button, PreLoader } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../api/services/tokenService';
import QuoteConflict from '../../../components/common/conflict-popups/QuoteConflict';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import SuccessModalBody from '../../../components/common/success-modal-body/SuccessModalBody';
import { useShopperContext } from '../../../context/shopper-context/ShopperContext';
import { CONFLICT_OPTION_LABELS, CONFLICT_USE_CASES, ERROR_ALERT_CONFIG, LANGUAGE_CODES, NAV_PATHS, NON_CONFLICT_SCREENS, REMEMBER_MY_DEVICE_NO, REMEMBER_MY_DEVICE_YES, SCREEN_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { recordActionSatelliteTrack } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import AgentConflictPopup from '../../secure/agent-conflict-popup/AgentConflictPopup';
import { DELETE_PAYLOAD_INPUT } from '../../secure/dashboard/DashboardCard/constants';
import { cancelInProgressApplication } from '../../secure/dashboard/DashboardCard/dashboardCardServices';
import { deleteInProgressDashboardCardPayload } from '../../secure/dashboard/DashboardCard/dashboardCardUtils';
import { resetSessionData } from '../../secure/logout/LogoutPupupUtils';
import NewPasswordForm from '../account-recovery/NewPasswordForm';
import { saveShopperAgentConnect } from '../basics/basics-landing/BasicsLandingService';
import { getAgentConnectPayload, getConflictInfo, getInProgressApplication, getNavigationInfo, populateUserDetails, processDemographicInfo, validateAgentConflict } from '../login/login-modal/loginUtils';
import QuestionAndAnswer from './QuestionAndAnswer';
import './TwoFAModal.scss';
import {
  getChannelIcon,
  getChannelLabel,
  getCloseBtnAnalyticsSuffix,
  getContactValue,
  getForgotPwdValidateOTPPayload,
  getForgotUsernameValidateOTPPayload,
  getOrderedContacts,
  getPayloadForForgotPwdSendOtp,
  getPayloadForForgotUsernameSendOtp,
  getPayloadForSendOtp,
  getUpdateLoginStatusPayload,
  getValidateOTPAndRegPayload,
  getValidateOTPPayload,
  getWebAccount
} from './TwoFAModalUtils';
import { CHANNEL_TYPES, OTP_REGX, OTP_RESPONSE_CODES, TWO_FA_USE_CASES, VALIDATE_OTP_ERROR_CODES, VALIDATE_OTP_SUCCESS_CODES } from './constants';
import { default as Content } from './twoFAModal.json';
import {
  forgotPwdSendOtp,
  forgotPwdValidateOTP,
  forgotUsernameSendOtp,
  sendOtp,
  validateOTPAndEmailUsername,
  validateOTPAndLogin,
  validateOTPAndRegister
} from './twoFAServices';

const numberOfDigits = 6;
const { REACT_APP_ENV } = process.env;

const TwoFAModalBody = ({
  showModal,
  showFooter = false,
  useCase,
  redirectionUseCase = false,
  onRedirectionUseCaseLogin = (validateOtpResponse: ValidateOTPResponse) => { },
  closeModal,
  hideModalHeading,
  updateModalHeading
}: {
  showModal: boolean;
  showFooter: boolean;
  useCase: string;
  redirectionUseCase?: boolean;
  onRedirectionUseCaseLogin?: (validateOtpResponse: ValidateOTPResponse) => void;
  closeModal: () => void;
  hideModalHeading: () => void;
  updateModalHeading: (heading: string) => void;
}) => {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { zipCodeRes } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.TWO_FA);
      content = response.data.data.iospagecontentList.items[0].pageContent?.TWO_FA;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.TWO_FA;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, [showModal]);

  const [showEnterOtpDiv, setShowEnterOtpDiv] = useState(false);
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
  const [rememberMyDevice, setRememberMyDevice] = useState(REMEMBER_MY_DEVICE_NO);
  const [otpError, setOtpError] = useState('');
  const [selectedChannel, setSelectedChannel] = useState<Contact>({
    channel: '',
    contactValue: ''
  });
  const [showconfirmation, setShowConfirmation] = useState({
    show: false,
    heading: '',
    desc: '',
    buttonText: ''
  });
  const [loading, setLoading] = useState(false);
  const [deviceAndUserId, setDeviceAndUserId] = useState({
    pingDeviceId: '',
    pingUserId: ''
  });
  const [otpServiceError, setOtpServiceError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [orderedContacts, setOrderedContacts] = useState<Contacts>([]);
  const [alreadyHaveAnOtp, setAlreadyHaveAnOtp] = useState(false);
  const [showEnterNewPwdScreen, setShowEnterNewPwdScreen] = useState({
    show: false,
    userName: ''
  });
  const [showIdentityScreen, setShowIdentityScreen] = useState(false);
  const [quoteConflictpopup, setQuoteConflictpopup] = useState(false);
  const [conflictUseCase, setConflictUseCase] = useState('')
  const [userLoginResponseData, setUserLoginResponseData] = useState<ValidateOTPResponse>();
  const [selectedConflictValue, setSelectedConflictValue] = useState('');
  const [isAgentConflict, setIsAgentConflict] = useState(false);
  const [selectedAgentConflictValue, setSelectedAgentConflictValue] = useState('');

  const otpBoxReference = useRef<any>([]);

  const {
    contacts,
    userName,
    role,
    pingRiskId,
    resetId,
    userFirstName,
    userLastName,
    userDateOfBirth,
    userEmailAddress,
    updateLoginStatus,
    updateLoginResponse,
    updateWebAccount,
    updateShowCreateProfileFlag,
    updateShowLoginButtonFlag,
    updateDemographicInfo,
    updateQuotes,
    updateFavourites,
    resetUserStore,
    updateRole
  } = useUserStore((state) => state);
  const { userPassword, setUserPassword } = useShopperContext()
  const [phone, setPhone] = useState({ number: '', type: '', ext: null });
  const [inProgressApplication, setInProgressApplication] = useState<Application>({} as Application)
  const { brand,
    updateCurrentCoverageMedicalPlan, updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan,
    selectedPlan, updateChangeDemographicFlag, updateIsApplyNowClicked,
    memberInfo, saveandResumeLaterFlag, deepLinkInfo, setIsPublicAgentConnect, isPublicAgentConnect
  } = useGlobalStore((state) => state);
  const registrationStoreData = useGlobalStore((state) => state);
  const { updateAgentInfo } = registrationStoreData
  const { macLoginWithCredUseCase } = deepLinkInfo;

  const navigate = useNavigate();
  const location = useLocation();

  const handleQuoteConflictClick = (value: string) => {
    setSelectedConflictValue(value);
  };

  const handleAgentConflictClick = (value: string) => {
    setSelectedAgentConflictValue(value)
  }

  useEffect(() => {
    restServiceError();
    restServiceError();

    if (showModal) {
      setAlreadyHaveAnOtp(false);
      setShowEnterOtpDiv(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      const helpdeskValue = pageContent?.TWOFA_OPTIONS.HELP_DESK_TEXT;
      const updatedContacts = getOrderedContacts(contacts, helpdeskValue, useCase, pageContent?.HIDE_EMAIL_WLP);
      const channelType = updatedContacts.find((channelType) => (channelType.contactValue !== '' ? channelType : ''));

      setOrderedContacts(updatedContacts);

      if (channelType) {
        setSelectedChannel(channelType);
      }
    }
  }, [contacts, showModal]);

  useEffect(() => {
    if (!quoteConflictpopup) {
      handleConflictResolution();
    }
  }, [quoteConflictpopup])

  useEffect(() => {
    if (!isAgentConflict) {
      handleAgentConflictResolution();
    }
  }, [isAgentConflict]);

  const handleAgentConflictResolution = async () => {

    if (selectedAgentConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen current working agent
      try {
        const agentPayload = getAgentConnectPayload(deepLinkInfo.agentConnectData);
        await saveShopperAgentConnect(agentPayload, useUserStore.getState().webAccountGUID);
        updateAgentInfo(deepLinkInfo.agentConnectData);
        closeModal();
        updateLoginStatus(true);
        setLoading(false);
      } catch (error) {
        console.warn('TwoFAModalBody.tsx handleAgentConflictResolution error :', error);
        setLoading(false);
      }
    }
    else if (selectedAgentConflictValue === CONFLICT_OPTION_LABELS.OPTION2) {
      //previous agent or already worked with agent
      try {
        await populateUserDetails(userLoginResponseData as ValidateOTPResponse);
        const getURL = await getNavigationInfo((userLoginResponseData as ValidateOTPResponse).shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
        closeModal();
        updateIsApplyNowClicked(false);
        navigate(getURL);
        setLoading(false);
        setIsPublicAgentConnect(false);
      }
      catch (error) {
        console.warn('TwoFAModalBody.tsx handleConflictResolution error :', error);
        setLoading(false);
      }
    }
  }

  const handleConflictResolution = async () => {

    if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen existing application
      resetSessionData()
      setLoading(false);
      closeModal();
      updateLoginStatus(true);
      navigate(NAV_PATHS.DASHBOARD)
    } else if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2) {
      //Delete the in progress application from server
      if (inProgressApplication && inProgressApplication.acn) {
        const payload: deleteApplicationPayload = deleteInProgressDashboardCardPayload(inProgressApplication.acn, DELETE_PAYLOAD_INPUT.CANCELREASON, DELETE_PAYLOAD_INPUT.PARTNETID, inProgressApplication.accessControlList[0]?.user?.userId);
        await cancelInProgressApplication(payload)
      }
      updateChangeDemographicFlag(true);
      setQuoteConflictpopup(false);
      updateLoginStatus(true);
      closeModal();
      setLoading(false);
      navigate(location.pathname);
    }
    else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2 && userLoginResponseData) {
      resetSessionData();
      await populateUserDetails(userLoginResponseData);
      const getURL = await getNavigationInfo(userLoginResponseData.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
      setLoading(false);
      closeModal();
      updateIsApplyNowClicked(false);
      navigate(getURL);
    }
    else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1 && conflictUseCase === CONFLICT_USE_CASES.QUOTE) {
      //Save the Quote I'm working on Now -- inactivate the saved quote by calling saveQuoteandDemographic API with empty quote data.
      // and reset the userStore.quotes and userStore.favourites
      updateChangeDemographicFlag(true);
      setQuoteConflictpopup(false);
      updateLoginStatus(true);
      setLoading(false);
      closeModal();
      navigate(location.pathname);
    }

    setConflictUseCase('');
  };

  const restServiceError = () => {
    setOtpServiceError({
      hasError: false,
      message: '',
      responseCode: ''
    });
  };

  const onValidateSuccessForgotPassword = (userName: string) => {
    //show Enter new password screen
    updateModalHeading(pageContent?.CONFIRMATION.HEADING);
    showFooter = false;
    setShowEnterNewPwdScreen({ show: true, userName: userName });
  };

  const initiateOTP = () => {
    restServiceError();
    if (selectedChannel.channel === CHANNEL_TYPES.HELP_DESK) {
      showEnterOtpScreen();
    } else {
      setLoading(true);
      if (useCase === TWO_FA_USE_CASES.FORGOT_PASSWORD) {
        forgotPwdSendOTPService();
      } else if (useCase === TWO_FA_USE_CASES.FORGOT_USERNAME) {
        forgotUsernameSendOTPService();
      } else {
        sendOTPService();
      }
    }
  };

  const forgotPwdSendOTPService = () => {
    const payload = getPayloadForForgotPwdSendOtp(selectedChannel, resetId, brand, pingRiskId);

    forgotPwdSendOtp(payload)
      .then((response) => {
        const data: ForgotPwdSendOTPResponse = response.data;

        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === OTP_RESPONSE_CODES.SEND_OTP_SUCCESS) {
          setDeviceAndUserId({
            pingDeviceId: data.pingDeviceId,
            pingUserId: data.pingUserId
          });

          setLoading(false);

          showEnterOtpScreen();
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });

          setLoading(false);
        }
      })
      .catch((error: AxiosError<SendtOTPError>) => {
        console.warn('TwoFaModal sendOtp response :', error);
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setOtpServiceError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message: pageContent?.TWOFA_OPTIONS.SEND_OTP_ISSUE
          });
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }
        setLoading(false);
      });
  };

  const forgotUsernameSendOTPService = () => {
    const payload = getPayloadForForgotUsernameSendOtp(userFirstName, userLastName, userDateOfBirth, userEmailAddress, pingRiskId, selectedChannel);

    forgotUsernameSendOtp(payload)
      .then((response) => {
        const data: ForgotPwdSendOTPResponse = response.data;

        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === OTP_RESPONSE_CODES.SEND_OTP_SUCCESS) {
          setDeviceAndUserId({
            pingDeviceId: data.pingDeviceId,
            pingUserId: data.pingUserId
          });

          setLoading(false);

          showEnterOtpScreen();
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });

          setLoading(false);
        }
      })
      .catch((error: AxiosError<SendtOTPError>) => {
        console.warn('TwoFaModal sendOtp response :', error);
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setOtpServiceError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message: pageContent?.TWOFA_OPTIONS.SEND_OTP_ISSUE
          });
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }
        setLoading(false);
      });
  };

  const sendOTPService = () => {
    const payload = getPayloadForSendOtp(selectedChannel, userName, role, pingRiskId);

    sendOtp(payload, useCase)
      .then((response) => {
        const data: SendOTPResponse = response.data;

        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === OTP_RESPONSE_CODES.SEND_OTP_SUCCESS) {
          setDeviceAndUserId({
            pingDeviceId: data.pingDeviceId,
            pingUserId: data.pingUserId
          });

          setLoading(false);

          showEnterOtpScreen();
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });

          setLoading(false);
        }
      })
      .catch((error: AxiosError<SendtOTPError>) => {
        console.warn('TwoFaModal sendOtp response :', error);
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setOtpServiceError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message: pageContent?.TWOFA_OPTIONS.SEND_OTP_ISSUE
          });
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const showEnterOtpScreen = () => {
    setOtp(new Array(numberOfDigits).fill(''));
    setOtpError('');
    setShowEnterOtpDiv(true);
  };

  const handleChange = (value: string, index: number) => {
    setOtpError('');
    let newArr = [...otp];

    if (OTP_REGX.test(value)) {
      newArr[index] = value;
      setOtp(newArr);
      if (value && index < numberOfDigits - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    }
  };

  const handleBackspaceAndEnter = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !(e.target as HTMLInputElement).value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && (e.target as HTMLInputElement).value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const validateOtp = () => {
    setOtpError('');

    if (otp.join('').length < 6 || otp.includes('')) {
      setOtpError(pageContent?.OPT_ERROR.OTP_SHOULD_BE_6);
    } else {
      if (otpError === '' && useCase === TWO_FA_USE_CASES.CREATE_PROFILE) {
        initiateRegWithOTP();
      } else if (otpError === '' && useCase === TWO_FA_USE_CASES.FORGOT_PASSWORD) {
        initiateForgotPwdOTPValidation();
      } else if (otpError === '' && useCase === TWO_FA_USE_CASES.FORGOT_USERNAME) {
        initiateForgotUsernameOTPValidation();
      } else {
        initiateOTPValidation();
      }
    }
  };

  const initiateRegWithOTP = () => {
    setLoading(true);
    restServiceError();
    let saleSource = {
      cid: useGlobalStore.getState().deepLinkInfo.refID,
      cidPhone: useGlobalStore.getState().deepLinkInfo.call,
      channel: 'APE-CD',
      quoteSrc: ''

    }
    const payload: ValidateOTPAndRegPayload = getValidateOTPAndRegPayload(
      registrationStoreData.username,
      userPassword,
      USER_DOMAINS.ROLE,
      otp.join(''),
      rememberMyDevice,
      pingRiskId,
      deviceAndUserId.pingDeviceId,
      deviceAndUserId.pingUserId,
      registrationStoreData.secretQuestionAnswers,
      { person: registrationStoreData.person },
      (useGlobalStore.getState().deepLinkInfo.refID || useGlobalStore.getState().deepLinkInfo.call) ? saleSource : undefined
    );

    validateOTPAndRegister(payload)
      .then((response) => {
        let data: ValidateOTPAndRegisterResponse = response.data;
        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === VALIDATE_OTP_SUCCESS_CODES.REGISTER_SUCCESS) {
          setLoading(false);
          setShowConfirmation({
            show: true,
            heading: pageContent?.CONFIRMATION.HEADING,
            desc: pageContent?.CONFIRMATION.DESCRIPTION,
            buttonText: pageContent?.CONFIRMATION.BUTTON
          });
          hideModalHeading();
          // data.loginResponse = data.registrationResponse;
          // data.loginResponse.role = 'SHOPPER';
          // // const data: ValidateOTPResponse = response.data;
          // handlePopulateUserLoginData(data);
          recordActionSatelliteTrack("successModalRegIos");
        }
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          if (errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.INVAID_OTP) {
            setOtpError(pageContent?.OPT_ERROR.INCORRECT_OTP);
          } else {
            setOtpServiceError({
              hasError: true,
              responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
              message:
                errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.MAX_ATTEMPTS
                  ? pageContent?.OPT_ERROR.MAX_ATTEMPTS
                  : `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
            });
          }
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }
        setLoading(false);
      });
  };

  const initiateForgotPwdOTPValidation = () => {
    setLoading(true);
    restServiceError();
    const payload: ForgotPwdValidateOtpPayload = getForgotPwdValidateOTPPayload(
      resetId,
      USER_DOMAINS.ROLE,
      otp.join(''),
      pingRiskId,
      deviceAndUserId.pingDeviceId,
      deviceAndUserId.pingUserId,
      alreadyHaveAnOtp,
      selectedChannel.channel
    );

    forgotPwdValidateOTP(payload)
      .then((response) => {
        const data: ForgotPWDValidateOTPResponse = response.data;
        setLoading(false);
        onValidateSuccessForgotPassword(data.userName);
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          if (errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.INVAID_OTP) {
            setOtpError(pageContent?.OPT_ERROR.INCORRECT_OTP);
          } else {
            setOtpServiceError({
              hasError: true,
              responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
              message:
                errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.MAX_ATTEMPTS
                  ? pageContent?.OPT_ERROR.MAX_ATTEMPTS
                  : `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
            });
          }
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }
        setLoading(false);
      });
  };

  const showEmailUsernameConfirmation = () => {
    setShowConfirmation({
      show: true,
      heading: pageContent?.CONFIRMATION.HEADING,
      desc: pageContent?.CONFIRMATION.EMAIL_USERNAME_DESC,
      buttonText: pageContent?.CONFIRMATION.BUTTON
    });
    hideModalHeading();
    recordActionSatelliteTrack("usernameSentForgotUserIos");
  };

  const initiateForgotUsernameOTPValidation = () => {
    setLoading(true);
    restServiceError();
    const payload: ForgotUsernameValidateOtpPayload = getForgotUsernameValidateOTPPayload(
      userFirstName,
      userLastName,
      userDateOfBirth,
      otp.join(''),
      userEmailAddress,
      pingRiskId,
      deviceAndUserId.pingDeviceId,
      deviceAndUserId.pingUserId,
      alreadyHaveAnOtp,
      selectedChannel.channel
    );

    validateOTPAndEmailUsername(payload)
      .then((response) => {
        const data: ValidateOTPResponse = response.data;
        setLoading(false);
        showEmailUsernameConfirmation();
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        console.warn('TwoFAModalBody.tsx validateOTPAndLogin error :', error);

        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          if (errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.FORGOT_USERNAME_INVALID_OTP) {
            setOtpError(pageContent?.OPT_ERROR.INCORRECT_OTP);
          } else {
            setOtpServiceError({
              hasError: true,
              responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
              message:
                errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.EMAIL_USERNAME_MAX_ATTEMPTS
                  ? pageContent?.OPT_ERROR.MAX_ATTEMPTS
                  : `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
            });
          }
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const initiateOTPValidation = () => {
    setLoading(true);
    restServiceError();
    const payload: ValidateOTPPayload = getValidateOTPPayload(
      userName,
      userPassword,
      role,
      otp.join(''),
      rememberMyDevice,
      pingRiskId,
      deviceAndUserId.pingDeviceId,
      deviceAndUserId.pingUserId,
      alreadyHaveAnOtp,
      selectedChannel.channel
    );

    validateOTPAndLogin(payload)
      .then(async (response) => {
        const data: ValidateOTPResponse = response.data;
        handlePopulateUserLoginData(data);
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        console.warn('TwoFAModalBody.tsx validateOTPAndLogin error :', error);

        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          if (errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.INVAID_OTP) {
            setOtpError(pageContent?.OPT_ERROR.INCORRECT_OTP);
          } else {
            setOtpServiceError({
              hasError: true,
              responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
              message:
                errorObj?.errorKey === VALIDATE_OTP_ERROR_CODES.MAX_ATTEMPTS
                  ? pageContent?.OPT_ERROR.MAX_ATTEMPTS
                  : `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
            });
          }
        } else {
          setOtpServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const handlePopulateUserLoginData = async (data: ValidateOTPResponse) => {
    setUserPassword(userPassword);
    if (data.loginResponse.forceChangePassword) {
      setLoading(false);
      const updateWA = getWebAccount(data);
      updateWebAccount(updateWA);
      closeModal();
      navigate(NAV_PATHS.HARD_RESET_PASSWORD);
    }
    else {
      setUserLoginResponseData(data);
      const updateLoginStatusPayload = getUpdateLoginStatusPayload(data, userName, userPassword, contacts);
      updateLoginResponse(updateLoginStatusPayload);
      let tokenGenerated = await getToken();
    if (tokenGenerated || REACT_APP_ENV === 'local') {
      const updateWA = getWebAccount(data);
      updateWebAccount(updateWA);

      if (data.shopperProfile?.agentConnect) { updateAgentInfo(data.shopperProfile?.agentConnect!); }
      else if (isPublicAgentConnect && !data.shopperProfile?.agentConnect) {
        try {
          const agentPayload = getAgentConnectPayload(deepLinkInfo.agentConnectData);
          await saveShopperAgentConnect(agentPayload, useUserStore.getState().webAccountGUID);
          updateAgentInfo(deepLinkInfo.agentConnectData);
        } catch (error) {
          console.warn('TwoFAModalBody.tsx handleAgentConflictResolution error :', error);
          setLoading(false);
        }
      }

      data?.shopperProfile?.demographicInfo && !useGlobalStore.getState().applicantFormDetails.length && processDemographicInfo(data?.shopperProfile?.demographicInfo)

      recordActionSatelliteTrack("loginSuccessModalIos");

      if (redirectionUseCase) {
        setLoading(false);
        closeModal();
        onRedirectionUseCaseLogin(data)
      } else {

        const currentInProgressApplication = await getInProgressApplication(data.loginResponse.webAccountGUID);
        currentInProgressApplication && setInProgressApplication(currentInProgressApplication)
        const application_conflict_flag = currentInProgressApplication && !NON_CONFLICT_SCREENS.includes(location.pathname)
        const quote_conflict_flag = await getConflictInfo(location.pathname, selectedPlan);

        if (currentInProgressApplication && !application_conflict_flag) {
          //There is a conflict with application in progress and local session data
          updateLoginStatus(true);
          setLoading(false)
          navigate(NAV_PATHS.DASHBOARD)
        } else if (application_conflict_flag) {
          setQuoteConflictpopup(true);
          setConflictUseCase(CONFLICT_USE_CASES.APPLICATION)
        }
        else if ((deepLinkInfo.agentConnectData && data?.shopperProfile?.agentConnect)
          && validateAgentConflict(deepLinkInfo.agentConnectData, data?.shopperProfile?.agentConnect)) {
          // agent conflict it will check with agent connect data from redirection URL
          setIsAgentConflict(true);
        } else if (quote_conflict_flag && data.shopperProfile?.quotes?.[0]?.quoteLineItem.length) {
          setQuoteConflictpopup(true);
          setConflictUseCase(CONFLICT_USE_CASES.QUOTE)
        } else {
          if ((!data.shopperProfile?.quotes?.[0]?.quoteLineItem.length)
            && (location.pathname === NAV_PATHS.PLAN_SUMMARY
              || location.pathname === NAV_PATHS.MEDICAL_LISTING
              || location.pathname === NAV_PATHS.DENTAL_LISTING
              || location.pathname === NAV_PATHS.VISION_LISTING)) {
            updateLoginStatus(true);
            if (saveandResumeLaterFlag) {
              updateChangeDemographicFlag(true);
            }
            navigate(location.pathname)
          }
          else {
            try {
              await populateUserDetails(data);
              let getURL = await getNavigationInfo(data.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
              if (macLoginWithCredUseCase) {
                navigate(NAV_PATHS.PLAN_RECOMMENDATIONS)
              } else {
                navigate(getURL);
              }
            } catch (error) {
              console.warn('Error in TwoFAModalBody.tsx :', error);
              setLoading(false);
            }
          }
          setLoading(false);
          closeModal();
        }
      }
    }
    else {
      setLoading(false);
    }
  }
  }
  const getSelectedChannel = (name: string, value: string, contactUid?: string) => {
    setSelectedChannel({ channel: name, contactValue: value, contactUid: contactUid });
  };

  const redirectToQA = () => {
    updateModalHeading(pageContent?.CONFIRM_YOUR_IDENTITY.HEADING);
    setShowIdentityScreen(true);
  };

  const redirectToChannels = () => {
    updateModalHeading(pageContent?.HEADING);
    setShowIdentityScreen(false);
  };

  const backToSelectChannel = () => {
    setAlreadyHaveAnOtp(false);
    setShowEnterOtpDiv(false);
  };

  const resendOTP = () => {
    initiateOTP();
  };

  const updateRememberMyDevice = () => {
    const updatedRememberMyDevice = rememberMyDevice === REMEMBER_MY_DEVICE_NO ? REMEMBER_MY_DEVICE_YES : REMEMBER_MY_DEVICE_NO;
    setRememberMyDevice(updatedRememberMyDevice);
    updatedRememberMyDevice === REMEMBER_MY_DEVICE_YES && recordActionSatelliteTrack("remDeviceOptionEnterPasscodeModalIos");
  };
  const showPasswordConfirmation = () => {
    hideModalHeading();
    setShowEnterNewPwdScreen({ show: false, userName: '' });
    setShowConfirmation({
      show: true,
      heading: pageContent?.CONFIRMATION.HEADING,
      desc: pageContent?.CONFIRMATION.CHANGE_PASSWORD_DESC,
      buttonText: pageContent?.CONFIRMATION.BUTTON
    });
  };
  const renderChannelOption = (channel: string, contactValue: string, contactUid?: string) => {
    if (contactValue !== '' && !loading) {
      return (
        <div key={channel} className={`security-code-option ${channel === selectedChannel.channel ? 'selectedoption' : ''}`}>
          <div className="fwc-radio">
            <input
              aria-label={`TwoFA Channel ${channel}`}
              type="radio"
              name={channel}
              value={contactValue}
              data-analytics={channel === CHANNEL_TYPES.HELP_DESK ? "deskBtnSendPasscodeModalIos" : channel.toLowerCase() + 'BtnSendPasscodeModalIos'}
              onClick={(e) => getSelectedChannel(channel, contactValue, contactUid)}
              checked={channel === selectedChannel.channel}
              onChange={() => { }}
            />
          </div>
          <span className={`sae-icon ${getChannelIcon(channel)}`}></span>
          <div className="text-col">
            <span className="option-label">{getChannelLabel(channel, pageContent)}</span>
            <span>{getContactValue(contactValue, channel, useCase)}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const getToken = async (): Promise<boolean> => {
    let returnVal = false;
    try {
      const response = await getBuildVersion();
      if (response.status === 200) {
        try {
          const tokenResponse = await generateTokenAPI();
          if (tokenResponse && tokenResponse.data.token) {
            useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
            returnVal = true;
          } else {
            returnVal = false;
            setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
          }
        } catch (error: any) {
          returnVal = false;
          setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
      }
    }
    catch (error: any) {
      returnVal = false;
      setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
    }
    finally {
      return returnVal;
    }
  }

  const setTokenError = (responseCode, responseMessage) => {
    setOtpServiceError({
      hasError: true,
      responseCode: responseCode ? responseCode : HTTP_STATUS_CODES.SERVER_ERROR.toString(),
      message: responseMessage ? responseMessage : `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
    });

  }

  const sendAgentData = async () => {
    try {
      const agentPayload = getAgentConnectPayload(deepLinkInfo.agentConnectData);
      await saveShopperAgentConnect(agentPayload, useUserStore.getState().webAccountGUID);
      updateAgentInfo(deepLinkInfo.agentConnectData);
    } catch (error) {
      console.warn('TwoFAModalBody.tsx sendAgentData in register error :', error);
      setLoading(false);
    }
  }



  const handleQuesandAnswerCallback = async (data: ValidateOTPResponse) => {
    if (redirectionUseCase) {
      let tokenGenerated = await getToken();
      const updateWA = getWebAccount(data);
      updateWebAccount(updateWA);

      if (tokenGenerated || REACT_APP_ENV === 'local') {
        setLoading(false);
        closeModal();
        onRedirectionUseCaseLogin(data);

      } else {
        setLoading(false);
        setOtpServiceError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
        });
      }
    }
    else {
      handlePopulateUserLoginData(data)
    }
  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div>
      {!showEnterOtpDiv && !showconfirmation.show && !showEnterNewPwdScreen.show && !showIdentityScreen && (
        <div className="twofa-wrapper">
          <p>{pageContent?.TWOFA_OPTIONS.DESCRIPTION}</p>
          {orderedContacts.map(({ channel, contactValue, contactUid }) => {
            return renderChannelOption(channel, contactValue, contactUid);
          })}
          {selectedChannel.channel === CHANNEL_TYPES.HELP_DESK && <p className="help-desk-info">{pageContent?.TWOFA_OPTIONS.HELP_DESK_INFO}</p>}
          {otpServiceError.hasError && !loading && (
            <InfoBar
              area={ERROR_ALERT_CONFIG.AREA}
              backgroundColor={'error'}
              labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
              type={ERROR_ALERT_CONFIG.ERROR}
              handleClose={() => {
                restServiceError();
              }}
            >
              <p className="message" dangerouslySetInnerHTML={{ __html: otpServiceError.message }} />
            </InfoBar>
          )}

          {loading ? (
            <PreLoader id={'two-fa-modal-loader'} />
          ) : (
            <div className="send-btn-wrapper">
              <span
                data-analytics="havePasscodeSendPasscodeModalIos"
                id="have-security-code"
                onClick={() => {
                  setAlreadyHaveAnOtp(true);
                  showEnterOtpScreen();
                }}
              >
                {pageContent?.TWOFA_OPTIONS.HAVE_SECURITY_CODE}
              </span>
              <Button id="send-btn" data-analytics="sendBtnSendPasscodeModalIos" onClick={() => initiateOTP()}>
                {selectedChannel.channel === CHANNEL_TYPES.HELP_DESK ? pageContent?.TWOFA_OPTIONS.ENTER_OTP_BUTTON : pageContent?.TWOFA_OPTIONS.SEND_BUTTON}
              </Button>
            </div>
          )}
        </div>
      )}
      {showEnterOtpDiv && !showconfirmation.show && !showEnterNewPwdScreen.show && !showIdentityScreen && (
        <div className="sixdigit-code-wrapper">
          {!alreadyHaveAnOtp && (
            <div className="sent-to">
              {selectedChannel.channel === CHANNEL_TYPES.HELP_DESK ? (
                <p>{pageContent?.TWOFA_OPTIONS.HELP_DESK_INFO}</p>
              ) : (
                <p>
                  {pageContent?.SIX_DIGIT_MODAL.SENT_TO} <b>{getContactValue(selectedChannel.contactValue, selectedChannel.channel, useCase)}.</b>
                </p>
              )}
            </div>
          )}
          {!loading && (
            <div>
              <span className="sixdigit-label">{pageContent?.SIX_DIGIT_MODAL.LABEL}</span>
              <div className="sixdigits-fields">
                {otp.map((digit, index) => (
                  <input
                    aria-label={`Otp Field ${index + 1}`}
                    type="text"
                    key={index}
                    value={digit}
                    maxLength={Math.max(0, 1)}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    ref={(reference) => (otpBoxReference.current[index] = reference)}
                    className={`${otpError ? 'otp-error-field' : ''}`}
                  />
                ))}
              </div>
              {otpError !== '' && (
                <p className={`${otpError ? 'otp-error fwc-input' : ''}`}>
                  <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="otp_error">
                    {otpError}
                  </span>
                </p>
              )}
              <div className="remember-device-wrapper">
                <input
                  aria-label={'Remember My Device'}
                  id="remember-my-device"
                  value={rememberMyDevice}
                  type="checkbox"
                  checked={rememberMyDevice === REMEMBER_MY_DEVICE_YES}
                  onClick={() => {
                    updateRememberMyDevice();
                  }}
                  onChange={() => { }}
                />{' '}
                <span>{pageContent?.SIX_DIGIT_MODAL.REMEMBER_MY_DEVICE}</span>
              </div>
            </div>
          )}
          {otpServiceError.hasError && !loading && (
            <InfoBar
              area={ERROR_ALERT_CONFIG.AREA}
              backgroundColor={'error'}
              labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
              type={ERROR_ALERT_CONFIG.ERROR}
              handleClose={() => {
                restServiceError();
              }}
            >
              <p className="message" dangerouslySetInnerHTML={{ __html: otpServiceError.message }} />
            </InfoBar>
          )}
          {loading ? (
            <PreLoader id={'two-fa-modal-loader'} />
          ) : (
            <div className="continue-btn-wrapper">
              <Button id="continue-btn" data-analytics={`${useCase === TWO_FA_USE_CASES.FORGOT_USERNAME ? "emailUserEnterPasscodeModalIos" : "continueBtnEnterPasscodeModalIos"}`} onClick={validateOtp}>
                {useCase === TWO_FA_USE_CASES.FORGOT_USERNAME ? pageContent?.SIX_DIGIT_MODAL.EMAIL_USERNAME_BUTTON : pageContent?.SIX_DIGIT_MODAL.CONTINUE_BUTTON}
              </Button>
            </div>
          )}
          <div className="resend-wrapper">
            <h6>{pageContent?.RESEND.HEADING}</h6>
            {alreadyHaveAnOtp || selectedChannel.channel === CHANNEL_TYPES.HELP_DESK ? (
              ''
            ) : (
              <>
                <span id="resend-code" className="resend" onClick={resendOTP} data-analytics="resendEnterPasscodeModalIos">
                  {pageContent?.RESEND.RESEND_CODE}
                </span>
                <span className="separator-bar">|</span>
              </>
            )}
            <span id="try-another-method" className="another-method" onClick={backToSelectChannel} data-analytics="anotherMethodEnterPasscodeModalIos">
              {pageContent?.RESEND.ANOTHER_METHOD}
            </span>
          </div>
        </div>
      )}
      {showIdentityScreen && !showEnterNewPwdScreen.show && !showconfirmation.show && (
        <QuestionAndAnswer
          redirectToChannels={redirectToChannels}
          useCase={useCase}
          closeModal={closeModal}
          onValidateSuccessForgotPassword={(userName) => onValidateSuccessForgotPassword(userName)}
          onValidateSuccessForgotUsername={() => showEmailUsernameConfirmation()}
          onRedirectionUseCaseLogin={(data: ValidateOTPResponse) => handleQuesandAnswerCallback(data)}
        />
      )}
      {showFooter && !showEnterNewPwdScreen.show && !showconfirmation.show && (
        <div className="twofa-modal-footer">
          {showIdentityScreen ? (
            <p id="can-not-get" className="cannotget">
              {pageContent?.RESEND.ANOTHER_METHOD}?
              <span data-analytics={"getCodeLinkSecurityQsIos"} id="back-to-channels" className="backtoqa" onClick={redirectToChannels}>
                {' '}
                {pageContent?.CONFIRM_YOUR_IDENTITY.GET_ONE_TIME_SECURITY_CODE}
              </span>
            </p>
          ) : (
            <p id="can-not-get" className="cannotget">
              {pageContent?.MODAL_FOOTER.CAN_NOT_GET_CODE}
              <span id="back-to-qa" data-analytics={`${showEnterOtpDiv ? "securityQsEnterPasscodeModalIos" : "securityQsSendPasscodeModalIos"}`} className="backtoqa" onClick={redirectToQA}>
                {' '}
                {pageContent?.MODAL_FOOTER.TAKE_ME_TO_QA}
              </span>
            </p>
          )}
        </div>
      )}
      {showconfirmation.show && (
        <SuccessModalBody
          heading={showconfirmation.heading}
          description={showconfirmation.desc}
          closeButtonText={showconfirmation.buttonText}
          analyticsSuffix={getCloseBtnAnalyticsSuffix(useCase)}
          closeModal={() => {
            // navigate('/individuals/');
            closeModal();
          }}
        />
      )}
      {showEnterNewPwdScreen.show && <NewPasswordForm userName={showEnterNewPwdScreen.userName} showConfirmationContent={() => showPasswordConfirmation()} />}
      {quoteConflictpopup &&
        <QuoteConflict showPopup={quoteConflictpopup} popupClose={() => setQuoteConflictpopup(false)} useCase={conflictUseCase}
          onClick={handleQuoteConflictClick}
        />
      }
      {isAgentConflict &&
        <AgentConflictPopup showPopup={isAgentConflict} popupClose={() => { setIsAgentConflict(false) }}
          currentAgentDetails={deepLinkInfo.agentConnectData || {} as AgentConnect}
          previousAgentConnectDetails={userLoginResponseData?.shopperProfile?.agentConnect || {} as AgentOfRecord}
          onClick={handleAgentConflictClick}
        />
      }
    </div>
  );
};

export default TwoFAModalBody;


