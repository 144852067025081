import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import DateInput from '../../../../components/common/date-input/DateInput';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import { formatPhoneNumber } from '../../../../components/common/global-header/contact-us/contactUsUtils';
import InputSelect from '../../../../components/common/input-select-combo/InputSelect';
import { DATE_FORMATS, LANGUAGE_CODES, REMEMBER_MY_DEVICE_NO, REMEMBER_MY_DEVICE_YES, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { validateDob, validateEmail, validateFirstName, validateLastName } from '../../account-recovery/AccountRecoveryUtils';
import './PersonalInfo.scss';
import { INPUT_TYPES, MOBILE_INPUT_OPTIONS } from './constants';
import { unFormatePhoneNumber, validatePhoneNumber } from './personalInfoUtils';
import { default as Content } from './personalinfo.json';

const PersonalInfo = ({
  nextClickToggle,
  setNextClickToggle,
  updatePersonalInfoStatus
}: {
  nextClickToggle: boolean;
  setNextClickToggle: () => void;
  updatePersonalInfoStatus: () => void;
}) => {
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [dobError, setDOBError] = useState('');
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [emailError, setEmailError] = useState({
    title: '',
    label1: '',
    label2: '',
    label3: ''
  });
  const [phoneError, setPhoneError] = useState('');

  const updatePersonalInfo = useGlobalStore((state) => state.updatePersonalInfo);

  const { zipCodeRes, person } = useGlobalStore((store) => store);

  const { firstName, lastName, dateOfBirth, emailAddress, emailConsent, phone } = person;

  const { type: phoneNumberType, number: phoneNumber } = phone;

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PERSONAL_INFO);
      let cmsResponse = response.data;
      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.PersonalInfo);
    } catch (error) {
      setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.PersonalInfo);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (nextClickToggle) {
      validatePersonalInfo();
      setNextClickToggle();
    }
  }, [nextClickToggle]);

  const handleFirstNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setFirstNameError('');
    updatePersonalInfo({ ...person, firstName: e.target.value.toString() });
  };

  const handleLastNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setLastNameError('');
    updatePersonalInfo({ ...person, lastName: e.target.value.toString() });
  };

  const handleEmailAddresschange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setEmailError({
      title: '',
      label1: '',
      label2: '',
      label3: ''
    });
    updatePersonalInfo({ ...person, emailAddress: e.target.value.toString() });
  };

  const handleDateOfBirthChange = (dob) => {
    setDOBError('');
    if (dob) {
      const updatedDobValue = moment(dob).format(DATE_FORMATS.YYYYMMDD);
      updatePersonalInfo({ ...person, dateOfBirth: updatedDobValue });
    } else {
      updatePersonalInfo({ ...person, dateOfBirth: '' });
    }
  };

  const onNumberTypeChange = (e: any) => {
    setPhoneError('');
    updatePersonalInfo({ ...person, phone: { ...phone, type: e.target.value } });
  };
  const onNumberInputChange = (e: any) => {
    setPhoneError('');
    const formatedNumber = formatPhoneNumber(e.target.value);
    updatePersonalInfo({ ...person, phone: { ...phone, number: formatedNumber } });
  };

  const handleEmailConsentChange = () => {
    const updatedEmailConsent = emailConsent === REMEMBER_MY_DEVICE_NO ? REMEMBER_MY_DEVICE_YES : REMEMBER_MY_DEVICE_NO;
    updatePersonalInfo({ ...person, emailConsent: updatedEmailConsent });
  };
  const resetFieldErrors = () => {
    setFirstNameError('');
    setLastNameError('');
    setEmailError({
      title: '',
      label1: '',
      label2: '',
      label3: ''
    });
    setDOBError('');
    setPhoneError('');
  };
  const validatePersonalInfo = () => {
    resetFieldErrors();
    const isValidFirstName = checkFirstName();
    const isValidLastName = checkLastName();
    const isValidEmail = checkEmail();
    const isValidDob = checkDob(dateOfBirth, true);
    const isValidPhoneNumber = checkPhoneNumber();
    if (isValidFirstName && isValidLastName && isValidEmail && isValidDob && isValidPhoneNumber) {
      updateRegistrationStore();
      updatePersonalInfoStatus();
    }
  };

  const updateRegistrationStore = () => {
    updatePersonalInfo({
      ...person,
      dateOfBirth: moment(dateOfBirth).format(DATE_FORMATS.YYYYMMDD),
      phone: {
        ...phone,
        number: unFormatePhoneNumber(phoneNumber)
      }
    });
  };
  const checkFirstName = () => {
    const { isValidFirstName, firstNameErrorMessage } = validateFirstName(firstName);

    if (!isValidFirstName) {
      setFirstNameError(firstNameErrorMessage);
    } else {
      setFirstNameError('');
    }

    return isValidFirstName;
  };

  const checkLastName = () => {
    const { isValidLastName, lastNameErrorMessage } = validateLastName(lastName);

    if (!isValidLastName) {
      setLastNameError(lastNameErrorMessage);
    } else {
      setLastNameError('');
    }

    return isValidLastName;
  };

  const checkPhoneNumber = () => {
    const { isValidPhoneNumber, phoneNumberErrorMessage } = validatePhoneNumber(phoneNumber, phoneNumberType);

    if (!isValidPhoneNumber) {
      setPhoneError(phoneNumberErrorMessage);
    } else {
      setPhoneError('');
    }

    return isValidPhoneNumber;
  };

  const checkDob = (date: any, isNextClicked = false) => {
    handleDateOfBirthChange(date);
    const { isValidDob, dobErrorMessage } = validateDob(date, isNextClicked);

    if (!isValidDob) {
      setDOBError(dobErrorMessage);
    } else {
      setDOBError('');
    }

    return isValidDob;
  };
  const checkEmail = () => {
    const { isValidEmail, emailErrorObj } = validateEmail(emailAddress);

    if (!isValidEmail) {
      setEmailError(emailErrorObj);
    } else {
      setEmailError({
        title: '',
        label1: '',
        label2: '',
        label3: ''
      });
    }

    return isValidEmail;
  };

  return (!contentLoaded ? <FullPageLoader /> :
    <div className="personal-info-content-wrapper">
      <div className="fwc-row fwc-row-bm persona-info-header-container">
        <h4 className="btm-xxs  title">
          <span id="personal-info-title" className="sae-icon sae-icon-contacts right-xxs"></span>
          {pageContent?.PERSONAL_INFORMATION}
        </h4>

        <span id="personal-info-all-fields-label" className="btm-xxs  all-fields-label">
          {pageContent?.ALL_FIELDS_ARE_REQUIRED}
        </span>
      </div>
      <div className="fwc-row fwc-row-bm div-container-gap">
        <div className="fwc-col-6">
          <div className="fwc-input">
            <label id="personal-info-first-name-label" className="fwc-label" htmlFor={'personal-info-first-name-input'}>
              {pageContent?.FIRST_NAME}
            </label>
            <input
              type="text"
              id="personal-info-first-name-input"
              name={INPUT_TYPES.FIRSTNAME}
              value={firstName}
              onChange={handleFirstNameChange}
              onBlur={checkFirstName}
            />
            {firstNameError.length > 0 && (
              <div className="fwc-row fwc-row-bm fwc-col-12">
                <div className="fwc-input">
                  <span id="personal-info-firstname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                    {' '}
                    {firstNameError}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fwc-col-6 fwc-row-bm">
          <div className="fwc-input">
            <label id="personal-info-last-name-label" className="fwc-label" htmlFor={'personal-info-last-name-input'}>
              {pageContent?.LAST_NAME}
            </label>
            <input
              type="text"
              id="personal-info-last-name-input"
              name={INPUT_TYPES.LASTNAME}
              value={lastName}
              onChange={handleLastNameChange}
              onBlur={checkLastName}
            />
            {lastNameError.length > 0 && (
              <div className="fwc-row fwc-row-bm fwc-col-12">
                <div className="fwc-input">
                  <span id="personal-info-lastname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                    {' '}
                    {lastNameError}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fwc-row fwc-row-bm div-container-gap">
        <div className="fwc-col-6 fwc-input">
          <DateInput
            className="profile-date-picker"
            label={pageContent?.DATE_OF_BIRTH}
            placeholder={pageContent?.DATE_PLACEHOLDER}
            id="personal-info-date-birth-input"
            defaultDate={dateOfBirth}
            maxDate={dayjs().format('YYYY-MM-DD')}
            name={INPUT_TYPES.DOB}
            onBlur={(e) => checkDob(e.target.value, false)}
          />
          {dobError && (
            <span id="profile-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
              {dobError}
            </span>
          )}
        </div>
        <div className="fwc-col-6 fwc-row-bm">
          <div className="fwc-input">
            <label id="personal-info-email-label" className="fwc-label" htmlFor={'personal-info-email-input'}>
              {pageContent?.EMAIL_ADDRESS}
            </label>
            <input
              placeholder={pageContent?.EMAIL_ADDRESS_PLACEHOLDER}
              id="personal-info-email-input"
              type="text"
              name={INPUT_TYPES.EMAIL}
              value={emailAddress}
              onChange={handleEmailAddresschange}
              onBlur={checkEmail}
            />
            {emailError.title && (
              <div>
                <span id="profile-email-error" role="alert" className="fwc-inline-icon fwc-icon-delete padding-top">
                  {emailError.title}
                </span>
                <ul>
                  {emailError.label1 && <li className="error-message">{emailError.label1}</li>}
                  {emailError.label2 && <li className="error-message">{emailError.label2}</li>}
                  {emailError.label3 && <li className="error-message">{emailError.label3}</li>}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fwc-row fwc-row-bm">
        <div className="fwc-col-6">
          <div className="fwc-input">
            <InputSelect
              label={pageContent?.PHONE_NUMBER}
              inputId={'personal-info-phone-number'}
              inputValue={formatPhoneNumber(phoneNumber)}
              inputName={phoneNumber}
              onSelectChange={onNumberTypeChange}
              onInputChange={onNumberInputChange}
              onInputBlur={checkPhoneNumber}
              options={MOBILE_INPUT_OPTIONS}
              selectId={'personal-info-phone-number-select'}
              selectName={'Test'}
              selectPlaceholder={pageContent?.SELECT_PHONE_TYPE}
              selectValue={phoneNumberType}
            ></InputSelect>
            {phoneError && (
              <div className="fwc-row fwc-row-bm fwc-col-12">
                <div className="fwc-input">
                  <span id="personal-info-phone-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                    {phoneError}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fwc-row fwc-row-bm">
        <input
          aria-label={'Email Consent Checkbox'}
          id="personal-info-agreement-checkbox"
          value={emailConsent}
          type="checkbox"
          checked={emailConsent === REMEMBER_MY_DEVICE_YES}
          onChange={() => { }}
          onClick={() => handleEmailConsentChange()}
        />
        <div className="agree-terms-wrapper">
          <p id="personal-info-agreement-text" className="fwc-label agree-terms-text">
            {pageContent?.AGREE_TEXT_1}
          </p>
          <p id="personal-info-agreement-text" className="fwc-label agree-terms-text">
            {pageContent?.AGREE_TEXT_2}
          </p>
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
