import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../../../../../components/common/full-page-loader/FullPageLoader';
import './UserLogin.scss';

import { HTTP_STATUS_CODES, STATUSES } from '../../../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../../../api/services/tokenService';
import InfoBar from '../../../../../components/common/info-bar/InfoBar';
import { useShopperContext } from '../../../../../context/shopper-context/ShopperContext';
import { ERROR_ALERT_CONFIG, NAV_PATHS } from '../../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../../store/globalStore';
import { useUserStore } from '../../../../../store/userStore';
import { INPUT_TYPES, LOGIN_RESPONSE_CODES, USER_AUTHENTICATED_RESPONSE_CODES } from '../../login-modal/constants';
import { loginUser } from '../../login-modal/loginServices';
import { getErrorMessage, getErrorObj, getLoginSuccessPayload, getWebAccount } from '../../login-modal/loginUtils';

const { REACT_APP_ENV } = process.env;

const UserLogin: React.FC<UserLoginProps> = ({ labels, initiateTwoFa, checkInprogressApplication }) => {
    const [userName, setUserName] = useState('');
    const [validUserName, setValidUserName] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [validPassword, setValidPassword] = useState(true);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const navigate = useNavigate();
    const { updateLoginResponse, updateLoginStatus, updateWebAccount } = useUserStore((state) => state);
    const { updateAgentInfo, } = useGlobalStore((state) => state);
    const { setUserPassword } = useShopperContext()

    const handleUserNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
        setValidUserName(true);
        setUserName(e.target.value);
    };

    const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
        setValidPassword(true);
        setPassword(e.target.value);
    };

    const resetLoginError = () => {
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    };

    const validateCredentials = () => {
        resetLoginError();
        setLoading(true);

        setValidUserName(userName.length > 0);
        setValidPassword(password.length > 0);

        if (userName.length > 0 && password.length > 0) {
            //Local validations passed
            authenticateUser();

        } else {
            //local validations failed
            setLoading(false);
        }
    };

    const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = getErrorObj(errorKey, labels);
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>${labels?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.SYSTEM_EXCEPTION_MESSAGE}`
            });
        }

        setLoading(false);
    };

    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse && tokenResponse.data.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
                    }
                } catch (error: any) {
                    returnVal = false;
                    setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
                }
            }
        }
        catch (error: any) {
            returnVal = false;
            setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
        finally {
            return returnVal;
        }
    }

    const setTokenError = (responseCode, responseMessage) => {
        setLoginError({
            hasError: true,
            responseCode: responseCode ? responseCode : HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: responseMessage ? responseMessage : `<b>${labels?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.SYSTEM_EXCEPTION_MESSAGE}`
        });

    }

    const handleUserLogin = useCallback(async (data: LoginResponse) => {
        setUserPassword(password)
        if (data?.loginResponse?.forceChangePassword) {
            setLoading(false);
            const updateWA = getWebAccount(data);
            updateWebAccount(updateWA);
            navigate(NAV_PATHS.HARD_RESET_PASSWORD);
        } else {
            const loginSuccesspayload: LoginSuccessPayload = getLoginSuccessPayload(data, userName);
            updateLoginResponse(loginSuccesspayload);
        if (data.responseMessage.responseCode === LOGIN_RESPONSE_CODES.TWO_FA_NEEDED && loginSuccesspayload.contacts.length > 0) {
            setLoading(false);
            //User must complete 2fa to login
            initiateTwoFa();
        } else {
            //User doesn't need 2fa
            let tokenGenerated = await getToken();
            if (tokenGenerated || REACT_APP_ENV === 'local') {
                const updateWA = getWebAccount(data);
                updateWebAccount(updateWA);
                data.shopperProfile?.agentConnect && updateAgentInfo(data.shopperProfile.agentConnect);
                updateLoginStatus(true);
                setLoading(false)
                checkInprogressApplication(data);
            }
            else {
                setLoading(false);
                handleLoginServerError(null);
            }
        }
        }
    }, [userName, password, updateLoginResponse, setLoading, updateLoginStatus]);

    const authenticateUser = () => {
        loginUser(userName, password)
            .then((response) => {
                const data: LoginResponse = response.data;

                if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === STATUSES.SUCCESS) {
                    const { message, responseCode } = data.responseMessage;

                    if (USER_AUTHENTICATED_RESPONSE_CODES.includes(responseCode)) {
                        //User has logged in
                        handleUserLogin(data);
                    } else {
                        //User has not logged in due to an error
                        //Add UI to handle user account locked etc
                        setLoginError({
                            hasError: true,
                            responseCode: responseCode,
                            message: message
                        });

                        setLoading(false);
                    }
                } else {
                    //Handle server error
                    handleLoginServerError(null);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('UserLogin.tsx validateCredentials error :', error);
                //Handle server error

                handleLoginServerError(error);
            });
    };


    return (
        <div className="user-login-form-wrapper">
            {loading && <FullPageLoader />}
            <div className="user-login-form-heading">
                <h2 className="heading">{labels.LOGIN_FORM_HEADER}</h2>
                <span className="description">{labels.LOGIN_FORM_DESCRIPTION}</span>
            </div>
            <div className={'fwc-input input-container'}>
                <label id="user-login-username-label" className="fwc-label" htmlFor={'user-login-username-input'}>
                    {labels.USER_NAME}
                </label>
                <input data-testid="user-login-username-input" id="user-login-username-input" type="text" value={userName} onChange={handleUserNameChange} />
                {!validUserName && (
                    <span id="user-login-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {getErrorMessage(labels, INPUT_TYPES.USERNAME, userName)}
                    </span>
                )}
            </div>
            <div className={'fwc-input input-container'}>
                <label id="user-login-password-label" className="fwc-label" htmlFor={'user-login-password-input'}>
                    {labels.PASSWORD}
                </label>
                <div className="password-input-container password-container-position">
                    <input data-testid="user-login-password-input" id="user-login-password-input" type={passwordVisible ? 'text' : 'password'} value={password} onChange={handlePasswordChange} />

                    <span
                        id="user-login-show-password-icon"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
                    />
                </div>
                {!validPassword && (
                    <span id="user-login-password-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {getErrorMessage(labels, INPUT_TYPES.PASSWORD, password)}
                    </span>
                )}
            </div>

            {loginError.hasError && !loading && (
                <InfoBar
                    area={ERROR_ALERT_CONFIG.AREA}
                    backgroundColor={'error'}
                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                    type={ERROR_ALERT_CONFIG.ERROR}
                    handleClose={() => {
                        resetLoginError();
                    }}
                >
                    <p className="message" dangerouslySetInnerHTML={{ __html: loginError.message }} />
                </InfoBar>
            )}

            <div className="user-login-button-wrapper">
                <button
                    data-testid="user-login-button"
                    id="user-login-button"
                    className={'fwc-btn fwc-btn-primary fwc-col-12 button-text'}
                    onClick={() => {
                        validateCredentials();
                    }}
                >
                    {labels.LOGIN_BUTTON}
                </button>
            </div>
            <div className="forgot-username-password-container">
                <span
                    id="user-login-forgot-username"
                    className="user-forgot-username"
                    onClick={() => {
                        navigate(NAV_PATHS.FORGOT_USERNAME);
                    }}
                >
                    {labels.FORGOT_USERNAME}
                </span>
                <span className="seperator-text">
                    {labels.OR}
                </span>
                <span
                    id="user-login-forgot-password"
                    className="user-forgot-password"
                    onClick={() => {
                        navigate(NAV_PATHS.FORGOT_PASSWORD);
                    }}
                >
                    {labels.FORGOT_PASSWORD}
                </span>
            </div>
        </div>
    )
}

export default UserLogin