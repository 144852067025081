import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import './SecurityQuestions.scss';
import { ANSWERFIELDS, ANSWER_POSSIBLE_ERROR, QUESTIONFIELDS, SECURITY_QUESTIONS } from './constants';
import { checkAnswerHasError, checkQuestionHasError, getSecretQuestionAnswers, getUpdatedAnswerErrors, getUpdatedQuestions } from './securityQuestionsUtils';
import { default as Content } from './securityquestions.json';

const SecurityQuestions = ({
  continueClickToggle,
  setContinueClickToggle,
  initiateRegistration
}: {
  continueClickToggle: boolean;
  setContinueClickToggle: () => void;
  initiateRegistration: (SecurityQAndA: SecurityQAndA[]) => void;
}) => {
  const [questions, setQuestion] = useState(SECURITY_QUESTIONS);
  const [selectedquestions, setSelectedQuestions] = useState(QUESTIONFIELDS);
  const [showQuestionError, setQuestionError] = useState(QUESTIONFIELDS);
  const [answers, setAnswers] = useState(ANSWERFIELDS);
  const [showAnswerError, setAnswerError] = useState(ANSWER_POSSIBLE_ERROR);
  const [isRequiredField, setIsRequiredField] = useState(false);
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { username, updateSecretQAndAs, zipCodeRes, secretQuestionAnswers } = useGlobalStore((state) => state);
  const { userPassword } = useShopperContext()

  useEffect(() => {
    const updatedSelectedQuestions = {
      question1: secretQuestionAnswers[0]?.securityQuestion ?? '',
      question2: secretQuestionAnswers[1]?.securityQuestion ?? '',
      question3: secretQuestionAnswers[2]?.securityQuestion ?? ''
    };
    setSelectedQuestions(updatedSelectedQuestions);

    const updatedSelectedAnswers = {
      answer1: secretQuestionAnswers[0]?.securityAnswer ?? '',
      answer2: secretQuestionAnswers[1]?.securityAnswer ?? '',
      answer3: secretQuestionAnswers[2]?.securityAnswer ?? ''
    };
    setAnswers(updatedSelectedAnswers)
  }, [])

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.SECURITY_QUESTIONS);
      let cmsResponse = response.data;
      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.SECURITY_QUESTION_ANSWER);
    } catch (error) {
      setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.SECURITY_QUESTION_ANSWER);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (continueClickToggle) {
      validateQuestionAndAnswers();
      setContinueClickToggle();
    }
  }, [continueClickToggle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getSecurityQuestion = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedQuestions = getUpdatedQuestions(questions, e);

    setQuestion(updatedQuestions);
    setQuestionError({ ...showQuestionError, [e.target.name]: 'selected' });
    setSelectedQuestions({
      ...selectedquestions,
      [e.target.name]: e.target.value
    });
  };

  const isQuestionSelected = (id: string) => {
    selectedquestions[id] === 'Select' || selectedquestions[id] === ''
      ? setQuestionError({ ...showQuestionError, [id]: 'notselected' })
      : setQuestionError({ ...showQuestionError, [id]: 'selected' });
  };
  const getAnswers = (e: { target: { name: string | number; value: string | number } }) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };
  const validateAnswer = (id: string) => {
    const answerErrors = getUpdatedAnswerErrors(id, SECURITY_QUESTIONS, answers, showAnswerError, username, userPassword);
    setAnswerError(answerErrors);
  };

  useEffect(() => {
    if (selectedquestions || answers) {
      const payload = getSecretQuestionAnswers(selectedquestions, answers);

      updateSecretQAndAs(payload);
    }

  }, [selectedquestions, answers])

  const validateQuestionAndAnswers = () => {
    if (checkQuestionHasError(selectedquestions, answers) || checkAnswerHasError(showAnswerError)) {
      setIsRequiredField(true);
    } else {
      const payload = getSecretQuestionAnswers(selectedquestions, answers);

      updateSecretQAndAs(payload);
      initiateRegistration(payload);
    }
  };

  const displayQuestionErrorMessage = (id: string) => {
    return (
      (showQuestionError[id] === 'notselected' || (isRequiredField && selectedquestions[id] === '')) && (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.QUESTIONFIELDERROR.SELECT_ONE}
        </span>
      )
    );
  };
  const displayAnswerErrorMessage = (id: string) => {
    if (showAnswerError[id].isempty || (isRequiredField && answers[id] === '')) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error" data-testid="errorRequired">
          {pageContent?.ANSWERFIELDERRORS.REQUIRED}
        </span>
      );
    } else if (showAnswerError[id].isUsernameAdded || showAnswerError[id].isPasswordAdded) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.ANSWERFIELDERRORS.SECURITY_ANSWER_HAS_USERNAME_PASSWORD}
        </span>
      );
    } else if (showAnswerError[id].specialCharacters || showAnswerError[id].isSpaceAdded || showAnswerError[id].isQuestionAdded) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error" data-testid="errorSpecialChar">
          {pageContent?.ANSWERFIELDERRORS.SPECIAL_CHARACTER}
        </span>
      );
    } else if (showAnswerError[id].minValueValidation) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.ANSWERFIELDERRORS.MORE_THAN_4}
        </span>
      );
    }
  };
  return (!contentLoaded ? <FullPageLoader /> :
    <div className="security-container">
      <div className="security-text-wrapper">
        <h2 className="security-heading">
          <span className="sae-icon sae-icon-keyhole-shield"></span>
          {pageContent?.HEADING}
        </h2>
        <p>{pageContent?.DESCRIPTION}</p>
        <ul>
          <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_1}</li>
          <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_2}</li>
          <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_3}</li>
        </ul>
      </div>
      <div className="security-questions-wrapper">
        <div className="fwc-select-list">
          <label className="fwc-label" htmlFor="question1-select">
            {pageContent?.QUESTIONFIELDLABEL.QUESTION_1}
          </label>
          <select
            name="question1"
            id="question1-select"
            onChange={getSecurityQuestion}
            onBlur={() => isQuestionSelected('question1')}
            value={selectedquestions['question1'] || ''}
            data-testid="question1"
          >
            {questions.map(
              (item, idx) =>
                (!item.isSelected || item.value === selectedquestions['question1']) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                )
            )}
          </select>
          {displayQuestionErrorMessage('question1')}
        </div>
        <div className="fwc-input">
          <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_1}</label>
          <input
            aria-label={'Security Answer One'}
            type="text"
            id="answer-one"
            name="answer1"
            onChange={getAnswers}
            onBlur={() => validateAnswer('answer1')}
            maxLength={20}
            data-testid="answer1"
            value={answers['answer1']}
          />
          {displayAnswerErrorMessage('answer1')}
        </div>
        <div className="fwc-select-list">
          <label className="fwc-label" htmlFor="question2-select">
            {pageContent?.QUESTIONFIELDLABEL.QUESTION_2}
          </label>
          <select
            name="question2"
            id="question2-select"
            onChange={getSecurityQuestion}
            onBlur={() => isQuestionSelected('question2')}
            value={selectedquestions['question2'] || ''}
            data-testid="question2"
          >
            {questions.map(
              (item, idx) =>
                (!item.isSelected || item.value === selectedquestions['question2']) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                )
            )}
          </select>
          {displayQuestionErrorMessage('question2')}
        </div>
        <div className="fwc-input">
          <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_2}</label>
          <input
            aria-label={'Security Answer Two'}
            type="text"
            id="answer-two"
            name="answer2"
            onChange={getAnswers}
            onBlur={() => validateAnswer('answer2')}
            maxLength={20}
            data-testid="answer2"
            value={answers['answer2']}
          />
          {displayAnswerErrorMessage('answer2')}
        </div>
        <div className="fwc-select-list">
          <label className="fwc-label" htmlFor="question3-select">
            {pageContent?.QUESTIONFIELDLABEL.QUESTION_3}
          </label>
          <select
            name="question3"
            id="question3-select"
            onChange={getSecurityQuestion}
            onBlur={() => isQuestionSelected('question3')}
            value={selectedquestions['question3'] || ''}
            data-testid="question3"
          >
            {questions.map(
              (item, idx) =>
                (!item.isSelected || item.value === selectedquestions['question3']) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                )
            )}
          </select>
          {displayQuestionErrorMessage('question3')}
        </div>
        <div className="fwc-input">
          <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_3}</label>
          <input
            aria-label={'Security Answer Three'}
            type="text"
            id="answer-three"
            name="answer3"
            onChange={getAnswers}
            onBlur={() => validateAnswer('answer3')}
            maxLength={20}
            data-testid="answer3"
            value={answers['answer3']}
          />
          {displayAnswerErrorMessage('answer3')}
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestions;
