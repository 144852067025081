import { Button } from '@sydney-broker-ui/ios';
import React from 'react';

import { getResetFilters, getUpdatedFilters } from './checkBoxListFilterUtils';

import './CheckBoxListFilter.scss';

const CheckBoxListFilter: React.FC<CheckBoxListFilterProps> = ({ filterOptions, labels, showFooterButtons = true, onApplyClick }) => {
  return (
    <div className="check-box-list-container">
      <ul className="filter-options-wrapper">
        {filterOptions.map((option) => {
          return (
            <li key={option.key} className={option.disabled ? 'check-box-label check-box-label-disabled' : 'check-box-label'}>
              <input
                aria-label={'Monthly cost filter'}
                className="filter-input"
                id={option.key}
                value={option.label}
                type="checkbox"
                checked={option.isChecked}
                disabled={option.disabled}
                data-analytics={option.analytics}
                onClick={() => {
                  onApplyClick && onApplyClick(getUpdatedFilters(filterOptions, option));
                }}
                onChange={() => { }}
              />
              <span
                data-testid={option.key}
                onClick={() => {
                  onApplyClick && onApplyClick(getUpdatedFilters(filterOptions, option));
                }}
              >
                {option.label}
              </span>
            </li>
          );
        })}
      </ul>

      {showFooterButtons && (
        <div className="bottom-buttons-wrapper">
          <button
            id="monthly-cost-reset"
            className="reset-button"
            onClick={() => {
              onApplyClick && onApplyClick(getResetFilters(filterOptions));
            }}
          >
            {labels?.RESET_BUTTON}
          </button>
          <Button
            btnType="secondary"
            id="apply-button"
            className="apply-button"
            onClick={() => {
              onApplyClick && onApplyClick(filterOptions);
            }}
          >
            {labels?.APPLY_BUTTON}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckBoxListFilter;
