const TWO_FA_USE_CASES = {
  LOGIN: 'LOGIN',
  CREATE_PROFILE: 'CREATE_PROFILE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_USERNAME: 'FORGOT_USERNAME'
};

const OTP_REGX = /^\d?$/;

const CHANNEL_TYPES = {
  TEXT: 'TEXT',
  VOICE: 'VOICE',
  EMAIL: 'EMAIL',
  HELP_DESK: 'HelpDesk'
};
const OTP_RESPONSE_CODES = {
  SEND_OTP_SUCCESS: 'SENDOTP000'
};

const VALIDATE_OTP_SUCCESS_CODES = {
  REGISTER_SUCCESS: 'REGISTER000'
};

const VALIDATE_OTP_ERROR_CODES = {
  INVAID_OTP: 'VALIDATEOTP001',
  FORGOT_PWD_INVALID_OTP: 'VALIDATEOTP006',
  FORGOT_USERNAME_INVALID_OTP: 'EMAILUSERNAME011',
  MAX_ATTEMPTS: 'VALIDATEOTP004',
  EMAIL_USERNAME_MAX_ATTEMPTS: 'EMAILUSERNAME012'
};

const VALIDATE_ANSWER_RESPONSE_CODES = {
  EMAIL_USERNAME_VALID_ANSWER: 'EMAILUSERNAME000',
  FORGOT_PWD_VALID_ANSWER: 'VALIDATEOTPORSECANSWER000'
};

const VALIDATE_ANSWER_ERROR_CODES = {
  EMAIL_USERNAME_INVALID_ANSWER: 'EMAILUSERNAME002',
  FORGOT_PWD_INVALID_ANSWER: 'VALIDATEOTP003',
  LOGIN_INVALID_ANSWER: 'VALIDATEOTP003',
  USER_ACCOUNT_DISABLED: 'VALIDATEOTP005',
  EMAIL_USERNAME_ACCOUNT_DISABLED: 'EMAILUSERNAME005'
};

const CHANGE_PWD_RESPONSE_CODE = {
  PASSWORD_CHANGED_SUCCESSFULLY: 'CHANGEPWD000'
};

const TEST_CONTACTS: Contacts = [
  {
    contactValue: 's**********@elevancehealth.com',
    channel: 'EMAIL'
  },
  {
    contactValue: '***-***-7852',
    channel: 'TEXT'
  },
  {
    contactValue: '***-***-7852',
    channel: 'VOICE'
  }
];

export {
  CHANGE_PWD_RESPONSE_CODE,
  CHANNEL_TYPES,
  OTP_REGX,
  OTP_RESPONSE_CODES,
  TEST_CONTACTS,
  TWO_FA_USE_CASES,
  VALIDATE_ANSWER_ERROR_CODES,
  VALIDATE_ANSWER_RESPONSE_CODES,
  VALIDATE_OTP_ERROR_CODES,
  VALIDATE_OTP_SUCCESS_CODES
};
