import { Button, ModalSlide, Toggle } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import { ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { MEDICATIONS_NOT_FOUND, MIN_CHARACTERS } from './constants';
import { getDrugList, getDrugsPlanSummary, getGenericDrugList } from './medicationSearchServices';
import { getContractCodesInFormat } from './medicationServicesUtils';
import NoMedicationsFound from './noDrugsFound/noMedicationsFound';
import { default as Content } from './showMyMedications.json';
import './showMyMedications.scss';

function ShowMyMedicationsSlider({ showSlider, onChange, contractCodes, onSubmit }: DrugProps) {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { agentSSOInfo, agentState } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.MEDICATIONS);
      content = response.data.data.iospagecontentList.items[0].pageContent?.Medications;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.Medications;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);

    }
    return true;
  }, [showSlider, contractCodes]);

  const initiate = async () => {
    await getContent();
  };
  useEffect(() => {
    initiate();
  }, []);

  const { isAtk } = agentSSOInfo;
  const countyCode = useGlobalStore((state) => state.state);
  const { drugsList, updatedDrugsList, updateMatchMedicationsToPlansStatus, updatedCoveredMedicationsList } = useGlobalStore((store) => store);
  const [genericToggle, setGenericToggle] = useState(false);
  const [drugName, setDrugName] = useState('');
  const [showInputErrorLabel, setShowInputErrorLabel] = useState(false);
  const [showDrugError, setShowDrugError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSearchDrugScreen, setShowSearchDrugScreen] = useState(false);
  const [showMainScreen, setShowMainScreen] = useState(true);
  const [showDrugNameMatchScreen, setShowDrugNameMatchScreen] = useState(false);
  const [selectedDrugIds, setSelectedDrugIds] = useState<DrugDetails[]>([]);
  const [savedDrugsList, setSavedDrugsList] = useState<DrugDetails[]>([]);
  const [suggestions, setSuggestions] = useState<DrugDetails[]>([]);
  const [showCheckedError, setShowCheckedError] = useState(false);
  const [searchedDrugInfo, setSearchedDrugInfo] = useState<DrugDetails[]>([]);
  const [genericDrugInfo, setGenericDrugInfo] = useState<DrugDetails[]>([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showInputLoading, setShowInputLoading] = useState(false);
  const initialRef: any = null;
  const listRef = useRef(initialRef);
  const [isFocus, setIsFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleSliderClose = useCallback(() => {
    onChange();
  }, [onChange]);

  const handleToggleChange = () => {
    setGenericToggle(!genericToggle);
  };
  // calling apis to fetch the drugs data
  const getGenericDrugInfo = async (drug) => {
    let data;
    let err;

    try {
      const response = await getGenericDrugList({ drug: drug });
      data = response?.data.response;
    } catch (error) {
      err = error;
    }
    return data;
  };

  const handleGenericDrugListResponse = (response) => {
    if (JSON.stringify(response.genericOptionsList) != 'null') {
      return response.genericOptionsList.drugInfoList;
    } else {
      setShowDrugError(false);
    }
    return [];
  };

  const getDrugData = async (drug) => {
    let data;
    let err;
    let payload: SearchDrugsPayload = { state: isAtk ? agentState : countyCode, drugName: drug };
    try {
      const response = await getDrugList(payload);
      data = response?.data.response;
    } catch (error) {
      err = error;
    }
    return data;
  };

  const handleDrugListResponse = (response) => {
    if (response?.drugInfoList.length > 0) {
      return response?.drugInfoList;
    } else {
      setShowDrugError(true);
    }

    return [];
  };

  useEffect(() => {
    if (!showInputLoading) {
      setTimeout(() => {
        setShowInputLoading(false);
      }, 1000);
    }
  }, [showInputLoading]);

  const handleOnChangeDynamicDrugList = (e) => {
    const value = e.target.value;
    setDrugName(value);
    let suggestions: DrugDetails[] = [];
    if (value.length === MIN_CHARACTERS) {
      setShowInputLoading(true);
      getDrugData(value).then((r) => {
        let val = handleDrugListResponse(r);

        const regex = new RegExp(`^${value}`, 'i');
        suggestions = val.sort().filter((v) => regex.test(v.drugLabel));
        setSuggestions(suggestions);
        setShowInputLoading(false);

        setShowInputErrorLabel(false);
      });
    }
    if (value.length < MIN_CHARACTERS) {
      setSuggestions([]);
    }
  };
  const validateMedicationInput = (e) => {
    if (!isHovered) {
      setIsFocus(false);
    }
    if (e.target.value.length < MIN_CHARACTERS && e.target.value.length > 0) {
      setShowInputErrorLabel(true);
    } else setShowInputErrorLabel(false);
  };
  const renderSuggestions = () => {
    const handleSuggestionClick = (suggestion) => {
      if (!searchedDrugInfo.length) {
        setSearchedDrugInfo([...searchedDrugInfo, suggestion]);
      } else {
        searchedDrugInfo.pop();
        setSearchedDrugInfo([...searchedDrugInfo, suggestion]);
      }

      setDrugName(suggestion.drugLabel);
      if (listRef && listRef.current) listRef.current.focus();
    };
    return (
      <>
        {isFocus && (
          <div
            className="shadow-lg absolute w-full"
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => {
                  const isMatch = suggestion.drugLabel.toLowerCase().indexOf(drugName.toLowerCase()) > -1;
                  return (
                    <div key={index}>
                      {isMatch && (
                        <li className="suggestions-list-item" key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion.drugLabel}
                        </li>
                      )}
                    </div>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </>
    );
  };
  const handleResetFecthedList = (e) => {
    setIsFocus(true);
    // setSuggestions([]);
    setShowInputErrorLabel(false);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();

    if (drugName.length < MIN_CHARACTERS) {
      setShowInputErrorLabel(true);
    } else {
      setSuggestions([]);
      handleMedicationsList();
      setShowSpinner(false);
      setShowMainScreen(false);
    }
  };

  const handleSearchAgain = (e) => {
    e.preventDefault();
    setDrugName('');
    setSearchedDrugInfo([]);
    setSavedDrugsList([]);
    setGenericToggle(false);
    setShowSearchDrugScreen(false);
    setShowMainScreen(true);
    setShowDrugNameMatchScreen(false);
    setShowNoResults(false);
  };

  const handleAddDrugList = (e) => {
    e.preventDefault();
    if (!savedDrugsList.length) {
      setShowCheckedError(true);
    } else {
      if (savedDrugsList.length) {
        const updatedSelectedDrugsList = Array.from(new Set([...drugsList, ...savedDrugsList]));
        updatedDrugsList(updatedSelectedDrugsList);
      }
      setShowDrugNameMatchScreen(true);
      setShowSearchDrugScreen(false);
      setShowMainScreen(false);
    }
  };
  const handleMatchMedication = (e) => {
    updateMatchMedicationsToPlansStatus(false)
    e.preventDefault();
    setShowSpinner(true);
    let payload: DrugSummaryPayload = { planList: { plan: getContractCodesInFormat(contractCodes) }, drugList: { drug: drugsList } };
    getDrugsPlanSummary(payload)
      .then((response) => {
        const data: DrugsSummaryResponse = response.data;
        const planSummaryList: MedicationsPlanSummary[] = data.response.planSummaryList.planSummary;
        updatedCoveredMedicationsList(planSummaryList);
        !drugsList.length ? updateMatchMedicationsToPlansStatus(false) : updateMatchMedicationsToPlansStatus(true);
        setShowSpinner(false);
        handleSliderClose();
      })
      .catch((error) => {
        setShowSpinner(false);
        console.warn('showMyMedications.tsx error :', error);
      });
  };

  const handleMedicationsList = () => {
    let err;
    let generic;
    let local_genericDrug: DrugDetails[] = [];

    if (genericToggle) {
      searchedDrugInfo.map((row, idx) => {
        getGenericDrugInfo(row).then((result) => {
          generic = handleGenericDrugListResponse(result);

          setGenericDrugInfo([...genericDrugInfo, generic]);
          generic.map((row, index) => {
            local_genericDrug.push(row);
          });
          setSearchedDrugInfo([...searchedDrugInfo, ...local_genericDrug]);
        });
      });
    }

    if (!searchedDrugInfo.length) {
      setShowNoResults(true);
    } else {
      try {
        setShowSpinner(true);
        setShowSearchDrugScreen(true);
      } catch (error) {
        err = error;
      }
    }
    //setDrugName('');
    return genericDrugInfo;
  };

  const handleShowDrugListScreen = () => {
    const drugList = searchedDrugInfo;

    const handleCheckboxChange = (event, val) => {
      let isSelected = event.target.checked;
      const checkedId = event.target.value;

      if (isSelected) {
        setSavedDrugsList([...savedDrugsList, val]);
      } else {
        setSavedDrugsList(savedDrugsList.filter((id) => id !== val));
      }
      if (checkedId !== '') {
        setShowCheckedError(false);
      }
    };
    return (
      <div className="fwc-col-12 sm-12">
        <h2 className="btm-xxs title">
          <span className="sae-icon sae-icon-pill right-xxs"></span>
          <span>{pageContent?.TITLE.NAME_MATCH}</span>
        </h2>

        <div className="startpageContent" dangerouslySetInnerHTML={{ __html: pageContent?.NameMatchHTML }}></div>
        <div className="fwc-row fwc-row-bm">
          <table id="Medications-Checklist">
            <thead>
              <tr>
                {/* <th></th>
                  <th>{Strings.LABELS.NAME}</th> */}
              </tr>
            </thead>
            <tbody>
              {drugList.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="checkbox-color">
                      <input
                        type="checkbox"
                        checked={drugsList.some((obj) => obj.drugLabel === row.drugLabel) || savedDrugsList.some((obj) => obj.drugLabel === row.drugLabel)}
                        onChange={(event) => {
                          handleCheckboxChange(event, row);
                        }}
                        // value={drugList[index]}
                        placeholder={row.drugLabel}
                      />
                    </div>
                  </td>
                  <td>{row.drugLabel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const handleDrugNameMatchScreen = (title, html) => {
    const data: DrugDetails[] = drugsList;
    const handleRemoveSelectedDrugData = (idx) => {
      const tempRows = [...data];
      tempRows.splice(idx, 1);
      updatedDrugsList(tempRows);
    };

    return (
      <div className="fwc-col-12 sm-12">
        <h2 className="btm-xxs title">
          <span className="sae-icon sae-icon-pill right-xxs"></span>
          {title}
        </h2>

        <div className="startpageContent" dangerouslySetInnerHTML={{ __html: html }}></div>
        <div className="fwc-row fwc-row-bm">
          <div className="fwc-col-6 sm-12">
            <table id="Medications-list">
              <thead>
                <tr>
                  <th>{pageContent?.LABELS.TH_NAME}</th>
                  <th>{pageContent?.LABELS.TH_ACTION}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td className="btm-xxs title"> {row.drugLabel}</td>

                    <td>
                      <a onClick={() => handleRemoveSelectedDrugData(index)}>
                        <div className="icon-wrapper">
                          <span className="sae-icon sae-icon-trash right-xxs"></span>
                          <p> {pageContent?.LABELS.DELETE}</p>
                        </div>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const handleMainSearchScreen = () => {
    if (pageContent) {
      return (
        <div className="fwc-col-12 sm-12">
          <h2 className="btm-xxs title">
            <span className="sae-icon sae-icon-pill right-xxs"></span>
            {pageContent?.TITLE.SEARCH}
          </h2>

          <div className="startpageContent" dangerouslySetInnerHTML={{ __html: pageContent?.startpageHTML }}></div>
          <div className="fwc-row fwc-row-bm">
            <div className="fwc-col-6 sm-12">
              <div className="fwc-input">
                <div className="medicationName-inputcontainer">
                  <input
                    type="text"
                    placeholder={pageContent?.FIELD.EXAMPLE}
                    onChange={(e) => handleOnChangeDynamicDrugList(e)}
                    value={drugName}
                    onFocus={(e) => handleResetFecthedList(e)}
                    name="medicationName"
                    id="medicationName"
                    aria-label="medicationList-label"
                    onBlur={(e) => validateMedicationInput(e)}
                    ref={listRef}
                  />
                  <div className="medicationName-icon-container">
                    {drugName.length >= MIN_CHARACTERS && showInputLoading && <span className="inputloader"></span>}
                  </div>
                </div>
                {renderSuggestions()}
              </div>

              {showInputErrorLabel && (
                <label id="medication-input-label" className="autoErromaxWidth error">
                  {pageContent?.FIELD.INPUT_ERROR}
                </label>
              )}
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: '10px' }}>
            {pageContent?.LABELS.GENERIC}
            <span style={{ display: 'inline-block' }} title="Include Generic Medications">
              {' '}
              <Toggle checked={genericToggle} onChange={handleToggleChange} name="generic" />
            </span>
          </div>

          <div className="startpageContent" dangerouslySetInnerHTML={{ __html: pageContent?.endpageHTML }}></div>
        </div>
      );
    }
  };

  const resetFilters = useCallback(() => {
    setDrugName('');
    setSearchedDrugInfo([]);
  }, []);

  useEffect(() => {
    if (showSlider && drugsList.length) {
      setShowDrugNameMatchScreen(true);
      setShowMainScreen(false);
      setShowNoResults(false);
      setShowSearchDrugScreen(false);
    } else {
      setShowMainScreen(true);
      setShowNoResults(false);
      setShowSearchDrugScreen(false);
      setShowDrugNameMatchScreen(false);
      resetFilters();
    }
  }, [resetFilters, showSlider]);

  const renderFooters = () => {
    return (
      <div>
        <span className="right-xxs">
          <Button
            btnType="secondary"
            id="modal-slide-cancel"
            onClick={() => {
              handleSliderClose();
            }}
          >
            {pageContent?.LABELS.CANCEL}
          </Button>
        </span>
        {showMainScreen && (
          <Button
            id="modal-slide-submit"
            onClick={(e) => {
              handleSearchButton(e);
            }}
          >
            {pageContent?.LABELS.SEARCH}
          </Button>
        )}

        {showSearchDrugScreen && (
          <span>
            <Button
              btnType="secondary"
              id="modal-slide-again"
              onClick={(e) => {
                handleSearchAgain(e);
              }}
            >
              {pageContent?.LABELS.SEARCH_AGAIN}
            </Button>

            <Button
              id="modal-slide-list"
              onClick={(e) => {
                handleAddDrugList(e);
              }}
            >
              {pageContent?.LABELS.LIST}
            </Button>
          </span>
        )}
        {showDrugNameMatchScreen && (
          <span>
            <Button
              btnType="secondary"
              id="modal-slide-again"
              onClick={(e) => {
                handleSearchAgain(e);
              }}
            >
              {pageContent?.LABELS.SEARCH_AGAIN}
            </Button>

            <Button
              id="modal-slide-match"
              onClick={(e) => {
                handleMatchMedication(e);
              }}
            >
              {pageContent?.LABELS.MATCH}
            </Button>
          </span>
        )}

        {showNoResults && (
          <Button
            id="modal-slide-submit"
            onClick={(e) => {
              handleSearchAgain(e);
            }}
          >
            {pageContent?.LABELS.SEARCH_DRUGS}
          </Button>
        )}
      </div>
    );
  };

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'show-medications-container'}>
      <ModalSlide direction="right" open={showSlider} onClose={() => handleSliderClose()}>
        <ModalSlide.Body>
          <div className={'slider-container'}>
            <div className="fwc-row fwc-row-wrap mgd-menu-row">
              {showSpinner && <FullPageLoader />}

              {showMainScreen && handleMainSearchScreen()}

              {showSearchDrugScreen && handleShowDrugListScreen()}
              {showDrugNameMatchScreen && handleDrugNameMatchScreen(pageContent?.TITLE.LIST, pageContent?.MedicationListHTML)}
              {showNoResults && <NoMedicationsFound {...MEDICATIONS_NOT_FOUND(pageContent)} />}
            </div>
          </div>
        </ModalSlide.Body>
        {showCheckedError && (
          <div className="error-container">
            <InfoBar
              area={ERROR_ALERT_CONFIG.AREA}
              backgroundColor={'error'}
              labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
              type={ERROR_ALERT_CONFIG.ERROR}
              onClose={() => {
                setShowCheckedError(false);
              }}
            >
              <p className="message">{pageContent?.NOTCHECKED}</p>
            </InfoBar>
          </div>
        )}
        <ModalSlide.Footer>{renderFooters()}</ModalSlide.Footer>
      </ModalSlide>
    </div>
  );
}

const ShowMyMedications = memo(ShowMyMedicationsSlider);
export default ShowMyMedications;
