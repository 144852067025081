import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

import { API_NAME_LIST } from '../../../api/api-name-list';

const forgotUserName = (payload: ForgotUserNamePayload) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_USERNAME, payload);
};

const forgotPassword = (payload: ForgotPasswordPayload) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_PASSWORD, payload);
};

const validateResetPwdId = (payload: ValidateResetPwdIdPayload) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATERESETPWDID, payload);
};

const hardResetPassword = (payload: ChangeShopperPasswordPayload) => {
  return axiosInstance.post(API_NAME_LIST.HARD_RESET_PASSWORD, payload);
};

export { forgotPassword, forgotUserName, hardResetPassword, validateResetPwdId };

