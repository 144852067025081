import { StateCreator } from 'zustand';

export const createPlanSummaryStore: StateCreator<PlanSummaryStore> = (set, get) => ({
  isApplyNowClicked: false,
  isPlanSummaryEditable: true,
  isSaveQuoteApiCalled: false,
  updateIsApplyNowClicked: (isApplyNowClicked: boolean) => set({ isApplyNowClicked: isApplyNowClicked }),
  updateIsPlanSummaryEditable: (isPlanSummaryEditable: boolean) => set({ isPlanSummaryEditable: isPlanSummaryEditable }),
  updateIsSaveQuoteApiCalled: (isSaveQuoteApiCalled: boolean) => set({ isSaveQuoteApiCalled: isSaveQuoteApiCalled }),
  resetPlanSummaryStates: () => set({ isApplyNowClicked: false, isPlanSummaryEditable: true, isSaveQuoteApiCalled: false })
});
