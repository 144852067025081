import { Button, PreLoader } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../api/constants';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import { useShopperContext } from '../../../context/shopper-context/ShopperContext';
import { ERROR_ALERT_CONFIG, LANGUAGE_CODES, REMEMBER_MY_DEVICE_NO, REMEMBER_MY_DEVICE_YES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import './TwoFAModal.scss';
import {
  getAnalyticsTag,
  getButtonText,
  getEmailUsernameWithQAndAErrorObj,
  getEmailUsernamewithQAndAPayload,
  getForgotPasswordwithQAndAPayload,
  getForgotPwdWithQAndAErrorObj,
  getLoginQAndAErrorObj,
  getLoginwithQAndAPayload,
  getrandomSecretQuestion
} from './TwoFAModalUtils';
import { TWO_FA_USE_CASES, VALIDATE_ANSWER_RESPONSE_CODES } from './constants';
import { default as Content } from './twoFAModal.json';
import { fogotPasswordValidateAnswer, validateAnswerAndEmailUsername, validateAnswerAndLogin } from './twoFAServices';


const QuestionAndAnswer = ({
  redirectToChannels,
  useCase,
  closeModal,
  onValidateSuccessForgotPassword,
  onValidateSuccessForgotUsername,
  onRedirectionUseCaseLogin = (validateOtpResponse: ValidateOTPResponse) => { },
}: {
  redirectToChannels: () => void;
  useCase: string;
  closeModal: () => void;
  onValidateSuccessForgotPassword: (userName: string) => void;
  onValidateSuccessForgotUsername: () => void;
  onRedirectionUseCaseLogin?: (validateOtpResponse: ValidateOTPResponse) => void;
}) => {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { brand, zipCodeRes } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.TWO_FA);
      content = response.data.data.iospagecontentList.items[0].pageContent?.TWO_FA;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.TWO_FA;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  const [answer, setAnswer] = useState('');
  const [answerError, setAnswerError] = useState('');
  const [rememberMyDevice, setRememberMyDevice] = useState(REMEMBER_MY_DEVICE_NO);
  const [randomQuestion, setRamdomQuestion] = useState<SecretQAndA>({
    index: 0,
    securityQuestion: '',
    securityAnswer: ''
  });
  const [serviceError, setServiceError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const {
    userFirstName,
    userEmailAddress,
    userLastName,
    userDateOfBirth,
    userName,
    role,
    resetId,
    pingRiskId,
    secretQuestionAnswers,
    updateLoginStatus
  } = useUserStore((state) => state);
  const { userPassword } = useShopperContext()
  const navigate = useNavigate();

  useEffect(() => {
    setRamdomQuestion(getrandomSecretQuestion(secretQuestionAnswers));
  }, [secretQuestionAnswers]);

  const restServiceError = () => {
    setServiceError({
      hasError: false,
      message: '',
      responseCode: ''
    });
  };

  const handleAnswerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerError('');
    setAnswer(e.target.value);
  };

  const isValidAnswer = () => {
    if (answer.length === 0) {
      setAnswerError(pageContent?.CONFIRM_YOUR_IDENTITY.REQUIRE_fIELD);
      return false;
    } else if (answer.length <= 3) {
      setAnswerError(pageContent?.CONFIRM_YOUR_IDENTITY.MIN_LETTERS);
      return false;
    } else {
      setAnswerError('');
      return true;
    }
  };

  const validateInput = () => {
    const isValidInput = isValidAnswer();
    if (isValidInput) {
      setLoading(true);
      restServiceError();
      switch (useCase) {
        case TWO_FA_USE_CASES.LOGIN:
          initiateLoginWithQAndA();
          break;
        case TWO_FA_USE_CASES.FORGOT_USERNAME:
          initiateEmailUsernameWithQAndA();
          break;
        case TWO_FA_USE_CASES.FORGOT_PASSWORD:
          initiateForgotPasswordWithQAndA();
          break;
        default:
          break;
      }
    }
  };
  const initiateForgotPasswordWithQAndA = () => {
    const payload: ForgotPasswordWithQAndAPayload = getForgotPasswordwithQAndAPayload(pingRiskId, resetId, randomQuestion, answer);
    fogotPasswordValidateAnswer(payload)
      .then((response) => {
        const data: ForgotPasswordWithQAndAResponse = response.data;
        if (data.responseMessage.responseCode === VALIDATE_ANSWER_RESPONSE_CODES.FORGOT_PWD_VALID_ANSWER) {
          setLoading(false);
          //show Enter new password screen
          onValidateSuccessForgotPassword(data.userName);
        }
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;
          const errorKey = errorObj?.errorKey ?? '';
          const serviceErrorObj = getForgotPwdWithQAndAErrorObj(errorKey, pageContent);
          setServiceError(serviceErrorObj);
        } else {
          setServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const initiateEmailUsernameWithQAndA = () => {
    const payload: EmailUsernamewithQAndAPayload = getEmailUsernamewithQAndAPayload(
      userFirstName,
      userLastName,
      userDateOfBirth,
      userEmailAddress,
      role,
      randomQuestion,
      answer,
      pingRiskId
    );

    validateAnswerAndEmailUsername(payload)
      .then((response) => {
        const data: EmailUsernamewithQAndAResponse = response.data;
        if (data.webAccountResponse.responseCode === VALIDATE_ANSWER_RESPONSE_CODES.EMAIL_USERNAME_VALID_ANSWER) {
          setLoading(false);
          onValidateSuccessForgotUsername();
        }
      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;
          const errorKey = errorObj?.errorKey ?? '';
          const serviceErrorObj = getEmailUsernameWithQAndAErrorObj(errorKey, pageContent);
          setServiceError(serviceErrorObj);
        } else {
          setServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const initiateLoginWithQAndA = () => {
    const payload: LoginwithQAndAPayload = getLoginwithQAndAPayload(userName, userPassword, role, randomQuestion, answer, rememberMyDevice, pingRiskId);

    validateAnswerAndLogin(payload)
      .then((response) => {
        const data: ValidateOTPResponse = response.data;
        onRedirectionUseCaseLogin(data);

      })
      .catch((error: AxiosError<ValidateOTPError>) => {
        console.warn('TwoFAModalBody.tsx validateOTPAndLogin error :', error);

        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;
          const errorKey = errorObj?.errorKey ?? '';
          const serviceErrorObj = getLoginQAndAErrorObj(errorKey, pageContent);
          setServiceError(serviceErrorObj);
        } else {
          setServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }

        setLoading(false);
      });
  };

  const onCancel = () => {
    redirectToChannels();
  };
  const updateRememberMyDevice = () => {
    const updatedRememberMyDevice = rememberMyDevice === REMEMBER_MY_DEVICE_NO ? REMEMBER_MY_DEVICE_YES : REMEMBER_MY_DEVICE_NO;

    setRememberMyDevice(updatedRememberMyDevice);
  };
  return (!contentLoaded ? <FullPageLoader /> :
    <div className="identity-wrapper">
      <div className="identity-desc">
        <p>{pageContent?.CONFIRM_YOUR_IDENTITY.DESCRIPTION}</p>
      </div>
      <div className="fwc-col-12 sm-12 fwc-row-bm identity-answer-field">
        <div className="fwc-input">
          <span>{pageContent?.CONFIRM_YOUR_IDENTITY.FIELD_LABEL}</span>
          <label className="fwc-label">{randomQuestion.securityQuestion}</label>
          {!loading && (
            <input
              id={'identity-answer-input'}
              name={'identityanswer'}
              type="text"
              value={answer}
              onChange={(e) => handleAnswerOnChange(e)}
              onBlur={isValidAnswer}
            />
          )}
          {answerError && (
            <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
              {answerError}
            </span>
          )}
        </div>
        {!loading && (
          <span className="assistance-note">
            {pageContent?.CONFIRM_YOUR_IDENTITY.FORGOTTEN_ANSWER}
            <b>{pageContent?.CONFIRM_YOUR_IDENTITY.ASSISTANCE_NUMBER}</b>
            {pageContent?.CONFIRM_YOUR_IDENTITY.FOR_ASSISTANCE}
          </span>
        )}
      </div>
      {useCase === TWO_FA_USE_CASES.LOGIN && !loading && (
        <div className="remember-device-wrapper">
          <input
            id="login-remember-my-device"
            value={rememberMyDevice}
            type="checkbox"
            checked={rememberMyDevice === 'Y' ? true : false}
            onClick={() => {
              updateRememberMyDevice();
            }}
            onChange={() => { }}
          />{' '}
          <span>{pageContent?.CONFIRM_YOUR_IDENTITY.LOGIN_REMEMBER_MY_DEVICE}</span>
        </div>
      )}
      {serviceError.hasError && !loading && (
        <InfoBar
          area={ERROR_ALERT_CONFIG.AREA}
          backgroundColor={'error'}
          labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
          type={ERROR_ALERT_CONFIG.ERROR}
          handleClose={() => {
            restServiceError();
          }}
        >
          <p className="message" dangerouslySetInnerHTML={{ __html: serviceError.message }} />
        </InfoBar>
      )}
      {loading ? (
        <PreLoader id={'two-fa-modal-loader'} />
      ) : (
        <div className="identity-buttons-wrapper">
          <Button data-analytics={"cancelBtnSecurityQsIos"} btnType="secondary" id="identity-cancel-button" onClick={() => onCancel()}>
            {pageContent?.CONFIRM_YOUR_IDENTITY.CANCEL_BUTTON}
          </Button>
          <Button
            data-analytics={getAnalyticsTag(useCase)}
            onClick={() => {
              validateInput();
            }}
          >
            {getButtonText(useCase, pageContent)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuestionAndAnswer;
