import { Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import QuoteConflict from '../../../../components/common/conflict-popups/QuoteConflict';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../../components/common/global-header/GlobalHeader';
import InfoBar from '../../../../components/common/info-bar/InfoBar';
import PinDobValidation from '../../../../components/common/pin-dob-validation/PinDobValidation';
import TwoFAModalBody from '../../two-fa-modal/TwoFAModalBody';

import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { generateTokenAPI, getBuildVersion } from '../../../../api/services/tokenService';
import { getZipcodeValidation } from '../../../../api/services/utilService';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { getPlanYear, handleSetApplicationSuccessRedirection, isFutureDate } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { cancelInProgressApplication } from '../../../secure/dashboard/DashboardCard/dashboardCardServices';
import { deleteInProgressDashboardCardPayload } from '../../../secure/dashboard/DashboardCard/dashboardCardUtils';
import { resetSessionData, resetUserData } from '../../../secure/logout/LogoutPupupUtils';
import { loginUser } from '../login-modal/loginServices';
import { getErrorMessage, getErrorObj, getInProgressApplication, getLoginSuccessPayload, getNavigationInfo, getWebAccount, populateUserDetails, populateUserDetailsWithoutDemographic, processDemographicInfo } from '../login-modal/loginUtils';
import { getAgentInfoFroEvenderInfo, getDemographicInfoFromEvenderInfo, getPinAndDobByQuotePayload, getProposedQuoteDemographicInfo, getProposedQuoteDetails, getSelectedPlansByEVenderInfo, getSelectedPlansByQuoteDetails, getSelectedPlansBySharedQuote, isColaradoConnectPlanSelected, isOnExchangePlanSelected } from './standAloneLoginUtils';
import { fetchQuoteDetails, getQuoteByDemographcisId, validatePinAndDobByQuoteId } from './standaloneLoginServices';

import { HTTP_STATUS_CODES, STATUSES } from '../../../../api/constants';
import { CONFLICT_OPTION_LABELS, CONFLICT_USE_CASES, ERROR_ALERT_CONFIG, LANGUAGE_CODES, NAV_PATHS, NON_FFM_STATES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, USER_DOMAINS, USER_ROLES } from '../../../../shared/globalConstants';
import { DELETE_PAYLOAD_INPUT } from '../../../secure/dashboard/DashboardCard/constants';
import { shouldShowExceptionMessageForApplyNow } from '../../../secure/proposal-quote-overview/proposalQuoteOverviewUtils';
import { getEncryptacnPayload, getSetApplicationData } from '../../plan-selection-summary/planSelectionSummaryUtils';
import { getEncryptAcn, setApplicationApi } from '../../plan-selection-summary/planSummaryServices';
import { TWO_FA_USE_CASES } from '../../two-fa-modal/constants';
import { INPUT_TYPES, LOGIN_RESPONSE_CODES, USER_AUTHENTICATED_RESPONSE_CODES } from '../login-modal/constants';
import { default as message } from '../login-modal/loginModal.json';
import { STANDALONE_LOGIN_REDIRECTION_USE_CASES } from './constants';
import './standAloneLogin.scss';

const { REACT_APP_ENV } = process.env;

const StandAloneLogin = () => {
  const [urlParams] = useSearchParams();
  const resetId = urlParams.get('resetId');

  const [userName, setUserName] = useState('');
  const [validUserName, setValidUserName] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [inProgressApplication, setInProgressApplication] = useState<Application>({} as Application)
  const [showConflictPopup, setShowConflictPopup] = useState(false);
  const [conflictUseCase, setConflictUseCase] = useState('')
  const [userLoginResponseData, setUserLoginResponseData] = useState<LoginResponse | ValidateOTPResponse>();
  const [selectedConflictValue, setSelectedConflictValue] = useState('');
  const [showPinAndDobValidation, setPinAndDobValidation] = useState(false);
  const [prd, setPassword] = useState('');
  const [hideContinueButton, setHideContinueButton] = useState(false);
  const [loginError, setLoginError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const { setUserPassword } = useShopperContext();
  const { isWellpoint, updateLoginResponse, updateLoginStatus, updateShowCreateProfileFlag, updateShowLoginButtonFlag, updateWebAccount, updateDemographicInfo, updateQuotes, updateFavourites, updateRole } = useUserStore((state) => state);
  const { zipCodeRes, deepLinkInfo, updateMemberInfo, updateCurrentCoverageMedicalPlan, updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan, updateCoverageType, updateAgentInfo, updateZipCodeResponse, updateZipCodeFormDetails, updatePlanTypes, updatePlanTypesFromProposal, updateApplicantFormDetails, updateSelectedPlan, updateSelectedDentalPlan, updateSelectedVisionPlan, updatePrposedQuoteDetails, updateDeepLinkStandaloneLoginUseCase, updateIsPlanSummaryEditable,
    updateFavouriteMedicalPlans, updateFavouriteDentalPlans, updateFavouriteVisionPlans, updateEditZipCodeRes, updateEditZipCodeFormDetails,
    updateEditPlanTypes, updateEditCoverageType, updatedDrugsList, updateMatchMedicationsToPlansStatus, updateCoverageDate, updateChangeDemographicFlag } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  const { standaloneLoginUseCase, brand, quoteId, state, proposalID, medicalContractCode, quoteSrc, language, demographicsId, evendorid, evendorInfo } = deepLinkInfo;

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.LOGIN_PAGE);
      let cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.Login);
    } catch (error) {
      setContent(message.data.iospagecontentList.items[0].pageContent.Login);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    if (standaloneLoginUseCase) {
      switch (standaloneLoginUseCase) {
        case STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL:
        case STANDALONE_LOGIN_REDIRECTION_USE_CASES.USER_LOGIN:
        case STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR:
        case STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL:
          updateShowCreateProfileFlag(true);
          updateShowLoginButtonFlag(true);
          break;
      }
    }
  }, [standaloneLoginUseCase]);

  const initiate = async () => {
    setLoading(true);
    await getContent();
    setLoading(false);
    setUserName('');
    setPassword('');
    setValidUserName(true);
    setValidPassword(true);
    resetLoginError();
  };


  useEffect(() => {
    if (!showConflictPopup) {
      handleConflictResolution();
    }

  }, [showConflictPopup])

  const handleConflictResolution = async () => {
    if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen existing application
      updateLoginStatus(true);
      navigate(NAV_PATHS.DASHBOARD)
    } else if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2) {
      //Delete the in progress application from server and parse proposed quote
      if (inProgressApplication && inProgressApplication.acn) {
        const payload: deleteApplicationPayload = deleteInProgressDashboardCardPayload(inProgressApplication.acn, DELETE_PAYLOAD_INPUT.CANCELREASON, DELETE_PAYLOAD_INPUT.PARTNETID, inProgressApplication.accessControlList[0]?.user?.userId);
        await cancelInProgressApplication(payload)
      }
      setShowConflictPopup(false);
      updateChangeDemographicFlag(true);
      updateLoginStatus(true);

      if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL) {
        processSharedQuote()
      } else if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR) {
        processEvendorInfo()
      } else if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL) {
        if (userLoginResponseData?.loginResponse.role === USER_ROLES.MEMBER) {
          handleProcessProposedQuote()
        } else {
          setLoading(false)
          setPinAndDobValidation(true)
        }
      }
    } else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2 && userLoginResponseData) {
      //User has chosen saved quote so handle login
      resetSessionData();
      setLoading(true);
      try {
        await populateUserDetails(userLoginResponseData);
        const getURL = await getNavigationInfo(userLoginResponseData.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
        setLoading(false);
        navigate(getURL);
      }
      catch (error) {
        console.error('Error in StandAloneLoginPage.tsx :', error);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
        setLoading(false);
      }
    }
    else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen proposed/shared quote
      setShowConflictPopup(false);
      updateLoginStatus(true);

      if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL) {
        processSharedQuote()
      } else if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR) {
        processEvendorInfo()
      } else if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL) {
        if (userLoginResponseData?.loginResponse.role === USER_ROLES.MEMBER) {

          handleProcessProposedQuote()
        } else {
          setLoading(false);
          setPinAndDobValidation(true)
        }
      }
    }

    setConflictUseCase('');
  };

  useEffect(() => {
    initiate();

    return () => {
      setLoading(false);
      resetFlagValues();
    };
  }, []);

  const handleProcessProposedQuote = async () => {
    const proposedQuote = await loadQuoteDetails(quoteId)
    if (proposedQuote && shouldShowExceptionMessageForApplyNow(proposedQuote.demographicInfo?.coverageEffectiveDate ?? '', pageContent?.CUTOFF_DATE_FOR_APPLY_NOW ?? '')) {
      setLoading(false);
      resetUserData();
      updateShowCreateProfileFlag(true);
      updateShowLoginButtonFlag(true);
      setHideContinueButton(true);
      setLoginError({
        hasError: true,
        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
        message: `<b>${pageContent?.EXCEPTION_MESSAGE_FOR_APPLY_NOW}</b> `
      });
    }
    else {
      processProposedQuote()
    }

  }


  const getToken = async (): Promise<boolean> => {
    let returnVal = false;
    try {
      const response = await getBuildVersion();
      if (response.status === 200) {
        try {
          const tokenResponse = await generateTokenAPI();
          if (tokenResponse && tokenResponse.data.token) {
            useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
            returnVal = true;
          } else {
            returnVal = false;
            if (REACT_APP_ENV !== 'local') {
              showQuoteError()
            }
          }
        } catch (error: any) {
          returnVal = false;
          if (REACT_APP_ENV !== 'local') {
            showQuoteError()
          }
        }
      }
    }
    catch (error: any) {
      returnVal = false;
      showQuoteError()
    }
    finally {
      return returnVal;
    }
  }

  const handleQuoteConflictClick = (value: string) => {
    setSelectedConflictValue(value);
  };

  const handlePinAndDobValidation = async (pin: string, dob: string) => {
    setLoading(true)
    const payload: PinAndDobByQuotePayload = getPinAndDobByQuotePayload(pin, quoteId, dob);

    validatePinAndDobByQuoteId(payload).then(async response => {
      const data: PinAndDobByQuoteResponse = response.data;

      if (data.valid && userLoginResponseData) {
        handleProcessProposedQuote()
      } else {
        setLoading(false)
        showQuoteError(false)
      }
    }).catch(error => {
      setLoading(false)
      showQuoteError(false)
      console.error('StandaloneLogin.tsx handlePinAndDobValidation error :', error)
    })
  }

  const handleStandAloneLogin = async (data: LoginResponse | ValidateOTPResponse) => {
    setLoading(true);
    const currentInProgressApplication = await getInProgressApplication(data.loginResponse.webAccountGUID);
    currentInProgressApplication && setInProgressApplication(currentInProgressApplication);
    updateLoginStatus(true);
    if (currentInProgressApplication) {
      resetFlagValues();
      setLoading(false);
      navigate(NAV_PATHS.DASHBOARD)
    } else {

      try {
        await populateUserDetails(data);
        let getURL = await getNavigationInfo(data.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
        navigate(getURL);
        setLoading(false);
      } catch (error) {
        console.error('Error in StandAloneLoginPage.tsx :', error);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
        setLoading(false);
      }
    }
  }

  const loadQuoteDetails = async (quoteId: string): Promise<GetAgentQuoteResponse | null> => {
    try {
      const response = await fetchQuoteDetails(quoteId);
      const data: GetAgentQuoteResponse = response.data;
      return data
    } catch (error) {
      console.error('standAloneLogin.tsx loadQuoteDetails error :', error);
      return null
    }
  }


  const showQuoteError = (resetUserDataFlag = true) => {
    setLoading(false)

    if (resetUserDataFlag) {
      updateLoginStatus(false);
      resetUserData();
    }

    setLoginError({
      hasError: true,
      responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
      message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
    });
  }

  const updateZipCodeDetails = async (zipCode: string) => {
    const response = await getZipcodeValidation({ zipCode: zipCode, marketSegment: USER_DOMAINS.MARKET_SEGMENT });
    return response;
  }

  const updateZipcodeDetailsinForm = (zipCode: string, response: any, coverageType: string) => {
    updateZipCodeResponse(response.data.zipCodeResponse.zipCode);
    const planYear = getPlanYear(response.data.zipCodeResponse?.zipCode?.coverageEffectiveDate?.coverageDt, response.data.zipCodeResponse.zipCode.coverageTypeFlag || coverageType, response.data.zipCodeResponse?.zipCode?.currentDateTimeInZone);
    updateZipCodeFormDetails(zipCode, response.data.zipCodeResponse.zipCode.county, planYear);
    updateEditZipCodeRes(response.data.zipCodeResponse.zipCode);
    updateEditZipCodeFormDetails(zipCode, response.data.zipCodeResponse.zipCode.county, planYear);
    updateEditPlanTypes(response.data.zipCodeResponse.zipCode.county, planYear);
    updateEditCoverageType(response.data.zipCodeResponse.zipCode.coverageTypeFlag);
  }

  const processSharedQuote = async () => {
    setLoading(true)

    try {
      const { demographicsId } = deepLinkInfo;
      const quoteDetailsResponse = await getQuoteByDemographcisId(demographicsId); //Get the quote details by demographics id
      const quoteDetails: QuoteByDemograohcisIdResponse = quoteDetailsResponse.data;
      const { demographicInfo, quotes } = quoteDetails.shopperProfileV2;
      if (demographicInfo.coverageEffectiveDate && isFutureDate(demographicInfo.coverageEffectiveDate)) { //Check if coverage effective date is future date
        await processDemographicInfo(demographicInfo); //Update demographic info
        userLoginResponseData && populateUserDetailsWithoutDemographic(userLoginResponseData, demographicInfo) //Pre populate other user details incuding member info
        const { selectedPlans, planTypes } = await getSelectedPlansBySharedQuote(demographicInfo, quotes); //Get the selected plans and plan types
        planTypes && updatePlanTypesFromProposal(planTypes)   //Pre populate selected plans and plan types
        selectedPlans.selectedMedicalPlan && updateSelectedPlan(selectedPlans.selectedMedicalPlan);
        selectedPlans.selectedDentalPlan && updateSelectedDentalPlan(selectedPlans.selectedDentalPlan);
        selectedPlans.selectedVisionPlan && updateSelectedVisionPlan(selectedPlans.selectedVisionPlan);
        demographicInfo.coverageEffectiveDate && updateCoverageDate(demographicInfo.coverageEffectiveDate);
        setLoading(false)
        resetFlagValues();
        navigate(NAV_PATHS.PLAN_SUMMARY)
      } else {
        setLoading(false)
        resetUserData();
        updateLoginStatus(false);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.APPLY_ERROR_TITLE}</b> <br> ${pageContent?.APPLY_ERROR}`
        });
      }
    } catch (error) {
      setLoading(true)
      console.error('standAloneLogin.tsx processSharedQuote error :', error);
      showQuoteError()
    }
  }

  const processEvendorInfo = async () => {
    setLoading(true)

    if (evendorInfo) {
      try {
        if (evendorInfo.reqEffDt && isFutureDate(evendorInfo.reqEffDt)) {
          const { appZipcode } = evendorInfo;
          const zipCodeResponse = await updateZipCodeDetails(appZipcode);
          const demographicInfo: DemographicInfo = getDemographicInfoFromEvenderInfo(evendorInfo, zipCodeResponse.data?.zipCodeResponse?.zipCode);
          updateZipcodeDetailsinForm(appZipcode, zipCodeResponse, demographicInfo?.coverageType || '');
          const evenderDetails: ProposedQuoteDetails = {
            county: demographicInfo.county ?? '',
            countyCode: demographicInfo.countyCode ?? '',
            zipcode: demographicInfo.zipCode ?? '',
            state: demographicInfo.state ?? '',
            brand: demographicInfo.brand ?? '',
            coverageType: demographicInfo.coverageType ?? '',
          }
          const agentInfo: AgentOfRecord = getAgentInfoFroEvenderInfo(evendorInfo)

          const { selectedPlans, planTypes } = await getSelectedPlansByEVenderInfo(evendorInfo, demographicInfo)
          const onExchange = isOnExchangePlanSelected(selectedPlans)
          const colaradoPlan = isColaradoConnectPlanSelected(selectedPlans)
          planTypes && updatePlanTypesFromProposal(planTypes)
          selectedPlans.selectedMedicalPlan && updateSelectedPlan(selectedPlans.selectedMedicalPlan);
          selectedPlans.selectedDentalPlan && updateSelectedDentalPlan(selectedPlans.selectedDentalPlan);
          selectedPlans.selectedVisionPlan && updateSelectedVisionPlan(selectedPlans.selectedVisionPlan);
          updateCoverageDate(evendorInfo.reqEffDt)
          updatePrposedQuoteDetails(evenderDetails);
          updateDemographicInfo(demographicInfo);
          agentInfo && updateAgentInfo(agentInfo);
          createApplication(onExchange, colaradoPlan)
          setLoading(false)
        } else {
          setLoading(false)
          resetUserData();
          updateLoginStatus(false);
          setLoginError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.APPLY_ERROR_TITLE}</b> <br> ${pageContent?.APPLY_ERROR}`
          });
        }
      } catch (error) {
        console.error('standAloneLogin.tsx processEvendorInfo error :', error);
        showQuoteError()
      }
    } else {
      showQuoteError()
    }
  }

  const processProposedQuote = async () => {
    setLoading(true)
    const proposedQuote = await loadQuoteDetails(quoteId)

    if (proposedQuote) {
      const proposedQuoteDetails: ProposedQuoteDetails = getProposedQuoteDetails(proposedQuote);
      try {
        if (proposedQuote.demographicInfo?.coverageEffectiveDate && isFutureDate(proposedQuote.demographicInfo?.coverageEffectiveDate)) {
          const zipCodeResponse = await updateZipCodeDetails(proposedQuote.demographicInfo?.zipCode || '');
          updateZipcodeDetailsinForm(proposedQuote.demographicInfo?.zipCode || '', zipCodeResponse, proposedQuote?.demographicInfo?.coverageType || '');
          const updatedDemoGraphicInfo: DemographicInfo = getProposedQuoteDemographicInfo(proposedQuote)
          const { selectedPlans, planTypes } = await getSelectedPlansByQuoteDetails(proposedQuote)
          const onExchange = isOnExchangePlanSelected(selectedPlans)
          const colaradoPlan = isColaradoConnectPlanSelected(selectedPlans)
          planTypes && updatePlanTypesFromProposal(planTypes)
          selectedPlans.selectedMedicalPlan && updateSelectedPlan(selectedPlans.selectedMedicalPlan);
          selectedPlans.selectedDentalPlan && updateSelectedDentalPlan(selectedPlans.selectedDentalPlan);
          selectedPlans.selectedVisionPlan && updateSelectedVisionPlan(selectedPlans.selectedVisionPlan);
          updateCoverageDate(proposedQuote.demographicInfo?.coverageEffectiveDate);
          updatePrposedQuoteDetails(proposedQuoteDetails);
          updateDemographicInfo(updatedDemoGraphicInfo);
          proposedQuote?.agent && updateAgentInfo(proposedQuote?.agent);
          createApplication(onExchange, colaradoPlan)
        } else {
          setLoading(false)
          resetUserData();
          updateLoginStatus(false);
          setLoginError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.APPLY_ERROR_TITLE}</b> <br> ${pageContent?.APPLY_ERROR}`
          });
        }
      } catch (error) {
        console.error('StandaloneLogin.tsx processProposedQuote error :', error)
        showQuoteError()
      }
    } else {
      showQuoteError()
    }
  }

  const getSetApplication = async (onExchange: boolean) => {
    setLoading(true);
    const payload = getSetApplicationData(onExchange);
    try {
      let response = await setApplicationApi(payload);
      if (response?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {
        const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(response?.data?.response?.acn)
        const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
        handleSetApplicationSuccessRedirection(!onExchange, encryptedacnResponse?.data?.encryptedACN, REACT_APP_ENV, isWellpoint)
      } else {
        showQuoteError(false);
      }
    } catch (error) {
      console.error('StandaloneLogin.tsx getSetApplication error :', error)
      showQuoteError(false);
    }
  }

  const navigateToPlanSummary = () => {
    updateIsPlanSummaryEditable(false)
    setLoading(false)
    resetFlagValues();
    navigate(NAV_PATHS.PLAN_SUMMARY)
  }

  const createApplication = (onExchange: boolean, colaradoPlan: boolean) => {
    if (colaradoPlan) {
      //Ideally will not happen for proposal flow
      navigateToPlanSummary()
    } else if (onExchange) {
      //Market Place Plans
      if (NON_FFM_STATES.includes(state)) {
        //Rediredt the user to plan summary
        navigateToPlanSummary()
      } else {
        if (state === SET_APPLICATION_CONSTANTS.KY) {
          getSetApplication(onExchange);
        } else {
          navigateToPlanSummary()
        }
      }
    } else {
      //Off Market plans
      getSetApplication(onExchange);
    }

  }

  const handleRedirectionUseCaseLogin = (loginData: ValidateOTPResponse) => {
    setUserLoginResponseData(loginData);
    switch (standaloneLoginUseCase) {
      case STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL:
      case STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR:
        handleRedirectionQuoteFlow(loginData)
        break;
      case STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL:
        handleSelfSentEmailFlow(loginData);
        break;
      default:
        break;
    }
  }

  const handleRedirectionQuoteFlow = async (data: LoginResponse | ValidateOTPResponse) => {
    setLoading(true);
    try {
      const currentInProgressApplication = await getInProgressApplication(data.loginResponse.webAccountGUID);
      currentInProgressApplication && setInProgressApplication(currentInProgressApplication)
      const application_conflict_flag = currentInProgressApplication && (quoteId || evendorid);
      const quote_conflict_flag = data.shopperProfile?.quotes?.[0]?.quoteLineItem.length && (quoteId || evendorid) ? true : false;
      updateLoginStatus(true);
      setLoading(false);
      if (currentInProgressApplication && !application_conflict_flag) {
        //If there is no application in progress and no conflict with proposed quote, ideally can never happen
        resetFlagValues();
        navigate(NAV_PATHS.DASHBOARD)
      } else if (application_conflict_flag) {
        //If there is an application in progress and conflict with proposed quote
        setConflictUseCase(CONFLICT_USE_CASES.APPLICATION)
        setShowConflictPopup(true);
      } else if (quote_conflict_flag) {
        //There is an active quote and conflict with proposed quote
        setConflictUseCase(CONFLICT_USE_CASES.QUOTE)
        setShowConflictPopup(true);
      } else {
        if (standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR) {
          processEvendorInfo();
        } else {
          //There is no conflict with proposed quote
          if (data.loginResponse.role === USER_ROLES.MEMBER) {
            handleProcessProposedQuote()
          } else {
            setPinAndDobValidation(true);
          }
        }
      }
    } catch (error) {
      console.error('StandaloneLogin.tsx getSetApplication error :', error)
      setLoading(false)
      showQuoteError();
    }
  }

  const handleUserLogin = async (data: LoginResponse) => {
    setUserPassword(prd)
    if (data?.loginResponse?.forceChangePassword) {
      setLoading(false);
      const updateWA = getWebAccount(data);
      updateWebAccount(updateWA);
      navigate(NAV_PATHS.HARD_RESET_PASSWORD);
    }
    else {
      const loginSuccesspayload: LoginSuccessPayload = getLoginSuccessPayload(data, userName);
      updateLoginResponse(loginSuccesspayload);
      setUserLoginResponseData(data);
      if (data.responseMessage.responseCode === LOGIN_RESPONSE_CODES.TWO_FA_NEEDED && loginSuccesspayload.contacts.length > 0) {
        setModalHeading(pageContent?.MODAL_HEADING);
        setLoading(false)
        setShowModal(true);
      } else {
        let tokenGenerated = await getToken();
        if (tokenGenerated || REACT_APP_ENV === 'local') {
          const updateWA = getWebAccount(data);
          updateWebAccount(updateWA);

          data.shopperProfile?.agentConnect && updateAgentInfo(data.shopperProfile.agentConnect);

          switch (standaloneLoginUseCase) {

            case STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL:
              handleRedirectionQuoteFlow(data)
              break;
            case STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR:
              handleRedirectionQuoteFlow(data);
              break;
            case STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL:
              handleSelfSentEmailFlow(data);
              break;
            case STANDALONE_LOGIN_REDIRECTION_USE_CASES.USER_LOGIN:
            default:
              handleStandAloneLogin(data)
              break;
          }
        }
        else {
          setLoading(false);
          showQuoteError()
        }
      }
    }
  };

  const handleSelfSentEmailFlow = async (data: LoginResponse | ValidateOTPResponse) => {
    setLoading(true);
    try {
      const currentInProgressApplication = await getInProgressApplication(data.loginResponse.webAccountGUID);
      currentInProgressApplication && setInProgressApplication(currentInProgressApplication)
      const application_conflict_flag = currentInProgressApplication && (STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL === standaloneLoginUseCase);
      const quote_conflict_flag = data.shopperProfile?.quotes?.[0]?.quoteLineItem.length && (STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL === standaloneLoginUseCase);
      updateLoginStatus(true);
      setLoading(false);
      if (currentInProgressApplication && !application_conflict_flag) {
        //If there is no application in progress and no conflict with proposed quote, ideally can never happen
        resetFlagValues();
        navigate(NAV_PATHS.DASHBOARD)
      } else if (application_conflict_flag) {
        //If there is an application in progress and conflict with proposed quote
        setConflictUseCase(CONFLICT_USE_CASES.APPLICATION)
        setShowConflictPopup(true);
      } else if (quote_conflict_flag) {
        //There is an active quote and conflict with proposed quote
        setConflictUseCase(CONFLICT_USE_CASES.QUOTE)
        setShowConflictPopup(true);
      } else {
        processSharedQuote()
      }
    } catch (error) {
      console.error('StandaloneLogin.tsx getSetApplication error :', error)
      setLoading(false)
      showQuoteError();
    }

  }



  const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
    if (isAxiosError(error)) {
      const errorObj = error.response?.data?.error;
      const errorKey = errorObj?.errorKey ?? '';
      const loginErrorObj = getErrorObj(errorKey, pageContent);
      setLoginError(loginErrorObj);
    } else {
      setLoginError({
        hasError: true,
        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
        message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
      });
    }

    setLoading(false);
  };

  const handleUserNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValidUserName(true);
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValidPassword(true);
    setPassword(e.target.value);
  };

  const resetLoginError = () => {
    setLoginError({
      hasError: false,
      message: '',
      responseCode: ''
    });
  };

  const onErrorPopupClose = () => {
    resetLoginError();
  };

  const authenticateUser = () => {
    loginUser(userName, prd)
      .then((response) => {
        const data: LoginResponse = response.data;
        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === STATUSES.SUCCESS) {
          const { message, responseCode } = data.responseMessage;

          if (USER_AUTHENTICATED_RESPONSE_CODES.includes(responseCode)) {
            handleUserLogin(data);
          } else {
            setLoginError({
              hasError: true,
              responseCode: responseCode,
              message: message
            });

            setLoading(false);
          }
        } else {
          //Handle server error

          handleLoginServerError(null);
        }
      })
      .catch((error: AxiosError<LoginError>) => {
        console.error('standAloneLogin.tsx validateCredentials error :', error);
        //Handle server error

        handleLoginServerError(error);
      });
  };

  const validateCredentials = () => {
    resetLoginError();
    setLoading(true);
    !standaloneLoginUseCase && updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.USER_LOGIN);
    setValidUserName(userName.length > 0);
    setValidPassword(prd.length > 0);

    if (userName.length > 0 && prd.length > 0) {
      //Local validations passed
      authenticateUser();
    } else {
      //local validations failed
      setLoading(false);
    }
  };

  const resetFlagValues = () => {
    updateShowCreateProfileFlag(false);
    updateShowLoginButtonFlag(false);
  };

  const handleCreateProfile = () => {
    resetFlagValues();
    navigate(NAV_PATHS.REGISTRATION);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setShowModal(false);
  }

  return (
    <div>
      {(loading || !contentLoaded) && <FullPageLoader />}
      <GlobalHeader notClickable={hideContinueButton} />
      {!showPinAndDobValidation && <div>
        <div className="fwc-row">
          <div className="header-container">
            <div className="fwc-row loginpageheader-container ">
              <div className={'fwc-input input-container'}>
                <div className="login-form-header">
                  <span>{pageContent?.LOGIN_FORM_HEADER}</span>
                </div>
                <div>
                  <label id="loginpage-username-label" className="fwc-label" htmlFor={'loginpage-username-input'}>
                    {pageContent?.USER_NAME}
                  </label>
                  <input id="loginpage-username-input" type="text" value={userName} onChange={handleUserNameChange} />
                </div>
                {!validUserName && (
                  <div className="fwc-row fwc-row-bm fwc-col-12">
                    <div className="fwc-input">
                      <span id="loginpage-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {' '}
                        {getErrorMessage(pageContent, INPUT_TYPES.USERNAME, userName)}
                      </span>
                    </div>
                  </div>
                )}
                <label id="loginpage-password-label" className="fwc-label" htmlFor={'loginpage-password-input'}>
                  {pageContent?.PASSWORD}
                </label>
                <div className="password-input-container password-container-position">
                  <input id="loginpage-password-input" type={passwordVisible ? 'text' : 'password'} value={prd} onChange={handlePasswordChange} />

                  <span
                    id="loginpage-show-password-icon"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
                  />
                </div>
                {!validPassword && (
                  <div className="fwc-row fwc-row-bm fwc-col-12">
                    <div className="fwc-input">
                      <span id="loginpage-password-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {' '}
                        {getErrorMessage(pageContent, INPUT_TYPES.PASSWORD, prd)}
                      </span>
                    </div>
                  </div>
                )}

                {loginError.hasError && !loading && (
                  <InfoBar
                    area={ERROR_ALERT_CONFIG.AREA}
                    backgroundColor={'error'}
                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                    type={ERROR_ALERT_CONFIG.ERROR}
                    handleClose={() => {
                      onErrorPopupClose();
                    }}
                  >
                    <p className="message" dangerouslySetInnerHTML={{ __html: loginError.message }} />
                  </InfoBar>
                )}

                <div>
                  <button
                    id="loginpage-login-button"
                    name='loginpage-login-button'
                    className={'fwc-btn fwc-btn-primary fwc-col-12 button-text'}
                    onClick={() => {
                      validateCredentials();
                    }}
                  >
                    {pageContent?.LOGIN_BUTTON}
                  </button>

                  <div className="hyper-link-container">
                    <Link
                      id="loginpage-forgot-username"
                      onClick={() => {
                        resetFlagValues();
                      }}
                      to="/individuals/profile/forgot-username"
                    >
                      {pageContent?.FORGOT_USERNAME}
                    </Link>
                    <label id="loginpage-forgot-password" className={'fwc-label seperator-text'}>
                      {pageContent?.OR}
                    </label>
                    <Link
                      id="loginpage-forgot-password"
                      onClick={() => {
                        resetFlagValues();
                      }}
                      to="/individuals/profile/forgot-password"
                    >
                      {pageContent?.FORGOT_PASSWORD}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-input input-container">
                <div className={'create-profile-container'}>
                  <h3 id="loginpage-create-profile-label" className="bottome-title">
                    {pageContent?.DO_NOT_HAVE_A_PROFILE_YET}
                  </h3>
                  <div className="create-profile-desc-container">
                    <p id="loginpage-create-profile-description" className={'login-description'}>
                      {pageContent?.CREATE_PROFILE_DESCRIPTION_LOGINPAGE}
                    </p>
                  </div>
                  <button
                    id="loginpage-create-profile-button"
                    onClick={() => {
                      handleCreateProfile();
                    }}
                    className={'fwc-btn fwc-btn-secondary fwc-col-12 button-text'}
                  >
                    {pageContent?.CREATE_PROFILE}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal open={showModal} onClose={handleModalClose} title={modalHeading}>
            <Modal.Body>
              <TwoFAModalBody
                useCase={TWO_FA_USE_CASES.LOGIN}
                redirectionUseCase={
                  standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL ||
                  standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR ||
                  standaloneLoginUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL}
                showModal={showModal}
                showFooter={true}
                closeModal={() => setShowModal(false)}
                hideModalHeading={() => setModalHeading('')}
                updateModalHeading={(heading) => setModalHeading(heading)}
                onRedirectionUseCaseLogin={handleRedirectionUseCaseLogin}
              />
            </Modal.Body>
          </Modal>
          {showConflictPopup &&
            <QuoteConflict showPopup={showConflictPopup} useCase={conflictUseCase} redirectionUseCase={standaloneLoginUseCase} popupClose={() => { setShowConflictPopup(false) }}
              onClick={handleQuoteConflictClick}
            />
          }
        </div>
      </div>}
      {showPinAndDobValidation && <PinDobValidation labels={pageContent.PIN_DOB_VALIDATION} error={loginError} initiatePinDobValidation={handlePinAndDobValidation} onErrorPopupClose={resetLoginError} hideContinueButton={hideContinueButton} />}
      <GlobalFooter />
    </div>
  );
}

export default StandAloneLogin;
