import React from 'react';

interface PhoneNumberInputProps {
    value: string; // Unmasked phone number passed via props
    id?: string;
    onChange: (unmaskedNumber: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange }) => {
    // Mask the displayed value
    const maskedValue = value && value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove non-numeric characters from the input value
        const inputValue = event.target.value.replace(/\D/g, '');
        onChange(inputValue); // Pass the unmasked number to the parent component
    };

    return (
        <input
            type="text"
            value={maskedValue}
            onChange={handleInputChange}
            placeholder=""
            maxLength={10}
            aria-label='Phone number'
        />
    );
};

export default PhoneNumberInput;
