import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const fetchEmailPlan = (payload: EmailPlanReq) => {
  return axiosInstance.post(API_NAME_LIST.EMAIL_PLANS, payload);
};

const atkSendProposal = (payload: AtkSendProposalPayload) => {
  return axiosInstance.post(API_NAME_LIST.ATK_SEND_PROPOSAL, payload);
};

const fetchPrintPlan = (payload: EmailPlanReq) => {
  return axiosInstance.post(API_NAME_LIST.PRINT_PLANS, payload);
};

const fetchAtkPrint = (payload: AtkPrintPayload) => {
  return axiosInstance.post(API_NAME_LIST.ATK_PRINT, payload);
};

const sendEmailVerificationCode = (payload: VerifyEmailPayload) => {
  return axiosInstance.post(API_NAME_LIST.SEND_EMAIL_VERIFICATION_CODE, payload);
};

export { atkSendProposal, fetchAtkPrint, fetchEmailPlan, fetchPrintPlan, sendEmailVerificationCode };
