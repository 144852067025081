import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalStore } from '../store/globalStore';
import { useUserStore } from '../store/userStore';

interface ProtectedRouteProps {
  children: JSX.Element;
  zipcodeCheck?: boolean;
  loggedInCheck?: boolean;
  agentSSOCheck?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, zipcodeCheck, loggedInCheck, agentSSOCheck }) => {

  const { zipcode, agentSSOInfo } = useGlobalStore((store) => store);
  const { loggedIn } = useUserStore((store) => store);
  const location = useLocation();
  // If there is no zipcode other authentication pages or public pages it should not open
  if (!zipcode && zipcodeCheck) {
    return <Navigate to="/individuals" replace state={{ from: location }} />; //From react-router-dom
    // or widndow.location.href = "/login" if not using react-router-dom
  }

  if (!loggedIn && loggedInCheck) {
    return <Navigate to="/individuals" replace state={{ from: location }} />;
  }

  if (!agentSSOInfo?.isAtk && agentSSOCheck) {
    return <Navigate to="/individuals" replace state={{ from: location }} />;
  }
  return children; // return the right page if user is cleared.
};

export default ProtectedRoute;
