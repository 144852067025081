import { USER_DOMAINS } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
const getRegisterShopperPayload = (
  stateCd: string,
  username: string,
  password: string,
  role: string,
  secretQuestionAnswers: SecurityQAndA[],
  rememberDevice: string,
  shopperProfile: PersonInfo
): RegisterShopperPayload => {
  let saleSource;
  if (useGlobalStore.getState().deepLinkInfo?.refID || useGlobalStore.getState().deepLinkInfo?.call) {
    saleSource = {
      cid: useGlobalStore.getState().deepLinkInfo?.refID,
      cidPhone: useGlobalStore.getState().deepLinkInfo?.call,
      mutable: false,
      quoteSrc: '',
      channel: 'APE-CD'
    };
  }
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      ...(stateCd !== '' && { stateCd: stateCd }),
      brand: useGlobalStore.getState().brand
    },
    shopperAccount: {
      username,
      password,
      role,
      secretQuestionAnswers,
      rememberDevice,
      saleSource
    },
    shopperProfile: {
      person: shopperProfile
    }
  };
};

const getRegisterShopperSuccessPayload = (
  registrationData: RegisterShopperResponse,
  userName: string,
  phoneNumber: string,
  role: string
): LoginSuccessPayload => {
  return {
    userName: userName,
    userFirstName: registrationData.registrationResponse.firstName,
    userLastName: registrationData.registrationResponse.lastName,
    userEmailAddress: registrationData.registrationResponse.emailAddress,
    userPhoneNumber: phoneNumber,
    role: role,
    contacts: registrationData.contacts,
    secretQuestionAnswers: registrationData.registrationResponse.secretQuestionAnswers,
    pingRiskId: registrationData.pingRiskId
  };
};

export { getRegisterShopperPayload, getRegisterShopperSuccessPayload };
