import moment from 'moment';
import {
  AGENT_TYPES,
  APPLICANT_TYPES,
  BOOLEAN_VALUES,
  DATE_FORMATS,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  STATES_TO_DISABLE_EDIT_QUOTE
} from '../../../shared/globalConstants';
import { getTodayDateForATK } from '../../../shared/utils/globalUtils';
import { useUserStore } from '../../../store/userStore';

const getQuoteOrderingsRecContracts = (quotes: Quotes[], demographicInfo: DemographicInfo) => {
  let quoteRecContracts: QuoteRecContracts = [];
  let quoteOrderings: QuoteOrderings = [];
  quotes.map((quote) => {
    const quoteOrdering: QuoteOrdering = {
      quoteId: quote.quoteId,
      quoteLineItem: quote?.quoteLineItem?.length
    };
    quoteOrderings.push(quoteOrdering);
    quote?.quoteLineItem.map((lineItem) => {
      const contract: QuoteRecContract = {
        quoteId: quote.quoteId,
        contractCode: lineItem.contract.contractCode,
        planType: lineItem.contract.planType,
        applicant: lineItem.applicant
      };

      quoteRecContracts.push(contract);
    });
  });

  return { quoteRecContracts, quoteOrderings };
};

const getQuoteRecContracts = (quoteRecContracts: QuoteRecContracts, lplanType: string) => {
  return quoteRecContracts.filter((contract) => contract.planType === lplanType);
};

const getContractBundle = (quoteRecContracts: QuoteRecContracts) => {
  const contractBundle: contractBundle[] = quoteRecContracts.map((contract) => {
    return {
      contractCode: contract.contractCode
    };
  });

  return contractBundle;
};

const getUniqueContractBundle = (contractBundle: contractBundle[]) => {
  const uniqueContractBundle: contractBundle[] = contractBundle.filter((obj, index) => {
    return index === contractBundle.findIndex((el) => obj.contractCode === el.contractCode);
  });
  return uniqueContractBundle;
};

const groupByQuoteId = (quoteRecContracts: QuoteRecContracts) => {
  return quoteRecContracts.reduce((groups, contract) => {
    if (!groups[contract.quoteId]) {
      groups[contract.quoteId] = [];
    }
    groups[contract.quoteId].push(contract);
    return groups;
  }, {});
};

/*
## Function: getComparedProducts

### Description:
This function is used to generate a list of compared products based on the provided quote contracts and recommended plans for medical, dental, and vision.

### Parameters:
- `quoteRecContracts` (QuoteRecContracts): An array of quote contracts.
- `medicalRecommendedPlans` (RecComparedPlans): An array of recommended medical plans.
- `dentalRecommendedPlans` (RecComparedPlans): An array of recommended dental plans.
- `visionRecommendedPlans` (RecComparedPlans): An array of recommended vision plans.

### Returns:
- `SelectedProducts`: An array of objects, each representing a selected product. Each object contains the following properties:
  - `medicalProduct`: The selected medical product contract.
  - `medicalApplicants`: The applicants for the selected medical product.
  - `dentalProduct`: The selected dental product contract.
  - `dentalApplicants`: The applicants for the selected dental product.
  - `visionProduct`: The selected vision product contract.
  - `visionApplicants`: The applicants for the selected vision product.
  - `matchedDoctors`: An array of matched doctors for the selected medical product.
  - 'matchedMedications': An array of matched medications for the selected medical product.
  - 'matchedDentists': An array of matched dentists for the selected dental product.
  - 'matchedEyeDoctors': An array of matched eye doctors for the selected vision product.

### Behavior:
The function first groups the quote contracts by quoteId using the `groupByQuoteId` function. Then, for each group of quote contracts, it finds the medical, dental, and vision contracts and the corresponding recommended plans. It then creates a `SelectedProduct` object for each group and adds it to the `comparedProducts` array.
*/
const getComparedProducts = (
  quoteRecContracts: QuoteRecContracts,
  medicalRecommendedPlans: RecComparedPlans,
  dentalRecommendedPlans: RecComparedPlans,
  visionRecommendedPlans: RecComparedPlans
): SelectedProducts => {
  let comparedProducts: SelectedProducts = [];
  const groupedContractsByQuote: QuoteRecContracts[] = Object.values(groupByQuoteId(quoteRecContracts));

  groupedContractsByQuote.forEach((quoteContracts: QuoteRecContracts) => {
    const medicalQuoteContract = quoteContracts.find((contract) => contract.planType === PLAN_TYPES_NAMES.MEDICAL);
    const dentalQuoteContract = quoteContracts.find((contract) => contract.planType === PLAN_TYPES_NAMES.DENTAL);
    const visionQuiteContract = quoteContracts.find((contract) => contract.planType === PLAN_TYPES_NAMES.VISION);
    const medicalContractObj = medicalRecommendedPlans.find((plan) => plan.contract.contractCode === medicalQuoteContract?.contractCode);
    const dentalContractObj = dentalRecommendedPlans.find((plan) => plan.contract.contractCode === dentalQuoteContract?.contractCode);
    const visionContractObj = visionRecommendedPlans.find((plan) => plan.contract.contractCode === visionQuiteContract?.contractCode);

    const comparedProduct: SelectedProduct = {
      medicalProduct: medicalContractObj?.contract,
      medicalApplicants: medicalQuoteContract?.applicant ?? [],
      matchedDoctors: [],
      matchedMedications: [],
      dentalProduct: dentalContractObj?.contract,
      dentalApplicants: dentalQuoteContract?.applicant ?? [],
      matchedDentists: [],
      visionProduct: visionContractObj?.contract,
      visionApplicants: visionQuiteContract?.applicant ?? [],
      matchedEyeDoctors: []
    };

    comparedProducts.push(comparedProduct);
  });

  return comparedProducts;
};

const getPlanTypesBySelectedProduct = (selectedProduct: SelectedProduct): string[] => {
  const planTypes: string[] = [];

  selectedProduct.medicalProduct && planTypes.push(PLAN_TYPES_NAMES.MEDICAL);
  selectedProduct.dentalProduct && planTypes.push(PLAN_TYPES_NAMES.DENTAL, PLAN_TYPES_NAMES.VISION);
  selectedProduct.visionProduct && planTypes.push(PLAN_TYPES_NAMES.DENTAL, PLAN_TYPES_NAMES.VISION);

  return Array.from(new Set(planTypes));
};

const getSelectedProduct = (
  quoteRecContracts: QuoteRecContracts,
  medicalRecommendedPlans: RecComparedPlans,
  dentalRecommendedPlans: RecComparedPlans,
  visionRecommendedPlans: RecComparedPlans,
  quoteId: string
): SelectedProduct => {
  let selectedProduct: SelectedProduct = {} as SelectedProduct;
  const groupedContractsByQuote: QuoteRecContracts[] = Object.values(groupByQuoteId(quoteRecContracts));
  const productsByQuoteId = groupedContractsByQuote.find((quoteContracts) => quoteContracts[0].quoteId === quoteId);

  if (productsByQuoteId) {
    const medicalQuoteContract = productsByQuoteId.find((contract) => contract.planType === PLAN_TYPES_NAMES.MEDICAL);
    const dentalQuoteContract = productsByQuoteId.find((contract) => contract.planType === PLAN_TYPES_NAMES.DENTAL);
    const visionQuiteContract = productsByQuoteId.find((contract) => contract.planType === PLAN_TYPES_NAMES.VISION);
    const medicalContractObj = medicalRecommendedPlans.find((plan) => plan.contract.contractCode === medicalQuoteContract?.contractCode);
    const dentalContractObj = dentalRecommendedPlans.find((plan) => plan.contract.contractCode === dentalQuoteContract?.contractCode);
    const visionContractObj = visionRecommendedPlans.find((plan) => plan.contract.contractCode === visionQuiteContract?.contractCode);

    selectedProduct = {
      medicalProduct: medicalContractObj?.contract,
      medicalApplicants: medicalQuoteContract?.applicant ?? [],
      matchedDoctors: [],
      matchedMedications: [],
      dentalProduct: dentalContractObj?.contract,
      dentalApplicants: dentalQuoteContract?.applicant ?? [],
      matchedDentists: [],
      visionProduct: visionContractObj?.contract,
      visionApplicants: visionQuiteContract?.applicant ?? [],
      matchedEyeDoctors: []
    };
  }

  return selectedProduct;
};

const getUpdatedApplicantDetails = (applicants: Applicant[], userDetails: UserInfo) => {
  let dummyId = 0;
  return applicants?.map((applicant: Applicant) => {
    dummyId++;

    if (applicant.applicantType === APPLICANT_TYPES.PRIMARY) {
      return {
        ...applicant,
        email: userDetails.email
      };
    } else {
      return {
        ...applicant
      };
    }
  });
};

const shouldShowExceptionMessageForApplyNow = (coverageEffectiveDate: string, cutOffDate: string) => {
  try {
    if (coverageEffectiveDate && cutOffDate) {
      const formattedCoverageEffectiveDate = moment.parseZone(coverageEffectiveDate).format(DATE_FORMATS.YYYYMMDDHHMMSS);
      const planYear = moment(formattedCoverageEffectiveDate, DATE_FORMATS.YYYYMMDDHHMMSS).year();
      const formatttedCutOffDate = moment(cutOffDate).format(DATE_FORMATS.YYYYMMDDHHMMSS);
      const cutOffDateYear = moment(formatttedCutOffDate, DATE_FORMATS.YYYYMMDDHHMMSS).year();
      if (planYear <= cutOffDateYear && (moment().isAfter(moment(cutOffDate)) || planYear < cutOffDateYear)) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error('proposalQuoteOverviewUtils.ts shouldShowExceptionMessage error :', error);
    return false;
  }
};

const getOldProposalStatus = (
  coverageEffectiveDate: string,
  coverageType: string,
  agentType: string,
  blockDentalForAgents: string,
  blockMedicallForAgents: string
) => {
  try {
    if (coverageEffectiveDate) {
      const formattedCoverageEffectiveDate = moment.parseZone(coverageEffectiveDate).format(DATE_FORMATS.YYYYMMDDHHMMSS);
      const planYear = moment(formattedCoverageEffectiveDate, DATE_FORMATS.YYYYMMDDHHMMSS).year();
      const currentYear = getTodayDateForATK().year();
      if (planYear < currentYear && (agentType !== AGENT_TYPES.HPA || agentType !== AGENT_TYPES.TELESALES || agentType !== AGENT_TYPES.TELEASSISTANT)) {
        return true;
      } else if (currentYear > planYear && (agentType === AGENT_TYPES.HPA || agentType === AGENT_TYPES.TELESALES || agentType === AGENT_TYPES.TELEASSISTANT)) {
        if (
          (coverageType === PLAN_TYPES.DENTAL || coverageType === PLAN_TYPES.VISION) &&
          blockDentalForAgents.toLocaleLowerCase() === BOOLEAN_VALUES.TRUE.toLocaleLowerCase()
        ) {
          return true;
        } else if (
          (coverageType === PLAN_TYPES.MDV || coverageType === PLAN_TYPES.MEDICAL) &&
          blockMedicallForAgents.toLocaleLowerCase() === BOOLEAN_VALUES.TRUE.toLocaleLowerCase()
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  } catch (error) {
    console.error('proposalQuoteOverviewUtils.ts getOldProposalStatus error :', error);
    return false;
  }
};

const checkForDisableQuote = (stateCode: string, agentType: string) => {
  return (
    !STATES_TO_DISABLE_EDIT_QUOTE.includes(stateCode) &&
    (agentType === AGENT_TYPES.AGENCY || agentType === AGENT_TYPES.GENERAL_AGENT) &&
    !useUserStore.getState().isWellpoint
  );
};

export {
  checkForDisableQuote,
  getComparedProducts,
  getContractBundle,
  getOldProposalStatus,
  getPlanTypesBySelectedProduct,
  getQuoteOrderingsRecContracts,
  getQuoteRecContracts,
  getSelectedProduct,
  getUniqueContractBundle,
  getUpdatedApplicantDetails,
  shouldShowExceptionMessageForApplyNow
};
